import {
  ApplicationType,
  Tenant
} from "@libs/api/gateways/plt/plt-gateway.dtos";

export interface TenantDetailsTableProps {
  tenant: Tenant;
}

export enum ItemsKeys {
  Name = "name",
  CrmId = "crmId",
  Type = "type",
  Country = "country",
  RelatedTenants = "related_tenants",
  CustomerTenantId = "customer_tenant_id",
  UserLimit = "user_limit",
  Permissions = "permissions",
  Fieldman = "fieldman"
}

export type LineItem = (
  | NameSection
  | CrmIdSection
  | TypeSection
  | CountrySection
  | UsersLimitSection
  | PermissionsSection
  | RelatedTenantsTenantsSection
  | FieldmanSection
) & {
  name: string;
};

export type NameSection = {
  section: ItemsKeys.Name;
  value: string;
};

export type CrmIdSection = {
  section: ItemsKeys.CrmId;
  value: string | undefined;
};

export type TypeSection = {
  section: ItemsKeys.Type;
  value: ApplicationType;
};

export type FieldmanSection = {
  section: ItemsKeys.Fieldman;
  value: string | undefined;
};

export type CountrySection = {
  section: ItemsKeys.Country;
  value: string;
};

export type UsersLimitSection = {
  section: ItemsKeys.UserLimit;
  value: number;
};

export type PermissionsSection = {
  section: ItemsKeys.Permissions;
  value: string[];
};

export type RelatedTenantsTenantsSection = {
  section: ItemsKeys.RelatedTenants;
  value: Tenant[];
};
