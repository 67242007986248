import { Form } from "@components/form/Form";
import { FormTextField } from "@components/form/fields/FormTextField";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";

import { FunctionComponent, useMemo, useState } from "react";

import { DefaultButton, Dialog } from "@bps/fluent-ui";
import { useUpdateTenant } from "@libs/api/gateways/plt/plt-gateway.hooks";
import { Tenant, TenantDto } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { toTenantDto } from "@libs/api/gateways/plt/plt-gateway.utils";
import { ValidationSchema } from "@bps/utils";
import { Validator } from "@components/form/validation/Validator";

interface TenantEditDetailsFormDialogProps {
  tenant: Tenant;
}

interface TenantDetailsFormValues extends Pick<Tenant, "name"> {}

const validator = new Validator<TenantDetailsFormValues>();

const schema: ValidationSchema<TenantDetailsFormValues> = {
  name: validator
    .string()
    .required({ message: "Please fill out this required field" })
};

const TenantEditDetailsFormDialogBase: FunctionComponent<TenantEditDetailsFormDialogProps & {
  onDismiss: () => void;
}> = ({ tenant, onDismiss }) => {
  const { error, mutateAsync: updateTenant } = useUpdateTenant();
  const defaultValues: TenantDetailsFormValues = useMemo(
    () => ({
      name: tenant.name
    }),
    [tenant]
  );

  const onSubmit = async (values: TenantDetailsFormValues) => {
    const payload: TenantDto = {
      ...toTenantDto(tenant),
      name: values.name
    };
    await updateTenant(payload);
    onDismiss();
  };

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title: "Update Tenant Details",
        showCloseButton: true,
        onDismiss
      }}
      minWidth={450}
    >
      <Form<TenantDetailsFormValues>
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        error={error}
        validate={values => validator.validateWithParse(values, schema)}
      >
        <FormTextField name="name" label="Name" required />
      </Form>
    </Dialog>
  );
};

export const TenantEditDetailsFormDialog: FunctionComponent<TenantEditDetailsFormDialogProps> = props => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltCatalogTenantWrite
  );
  return (
    <>
      <DefaultButton
        onClick={() => setShowDialog(true)}
        styles={{ root: { width: "fit-content" } }}
        disabled={!hasWritePermission}
        title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
      >
        Edit details
      </DefaultButton>

      {showDialog && (
        <TenantEditDetailsFormDialogBase
          {...props}
          onDismiss={() => setShowDialog(false)}
        />
      )}
    </>
  );
};
