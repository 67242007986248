import { observer } from "mobx-react";
import {
  CheckUpdateCommandButton,
  DeleteEntityEventsSiteCommandButton,
  DownloadSiteAgentCommandButton,
  HealthCheckCommandButton,
  InstallPrerequisitesCommandButton,
  InstallSiteAgentCommandButton,
  PingSiteCommandButton,
  ProvisionFeatureCommandButton,
  RecalculateAvailabilityCommandButton,
  RestartSiteComponentCommandButton,
  SnapshotTablesCommandButton,
  StartSiteComponentCommandButton,
  StopSiteComponentCommandButton,
  UninstallSiteAgentCommandButton,
  UpdateLogLevelCommandButton,
  UpdateSiteAgentCommandButton,
  UploadLogsCommandButton
} from "modules/tenants/sections/pros/dashboard/site-commands";
import { ModifyDatabaseCommandButton } from "modules/tenants/sections/pros/dashboard/site-commands/ModifyDatabaseCommandButton";
import { SiteUpdateCommandButton } from "modules/tenants/sections/pros/dashboard/site-commands/SiteUpdateCommandButton";

import { Heading, Stack } from "@bps/fluent-ui";
import { SecurableComponent } from "@components/SecurableComponent";
import { Permissions } from "@libs/permissions/permissions.enum";

import { CommandRunnerProvider } from "../../_shared-models/CommandRunner";
import { CommandRunnerStatusBar } from "./CommandRunnerStatusBar";
import { UpdateSecretsCommandButton } from "./site-commands/UpdateSecretsCommandButton";

interface SiteCommandsProps {
  siteId: string;
}

export const SiteCommands = observer(({ siteId }: SiteCommandsProps) => {
  return (
    <CommandRunnerProvider>
      <Heading variant="heading">Site Health</Heading>
      <Stack horizontal wrap>
        <SecurableComponent permissions={[Permissions.ProsSiteManagementRead]}>
          <HealthCheckCommandButton siteId={siteId} />
        </SecurableComponent>
        <SecurableComponent permissions={[Permissions.ProsSiteManagementRead]}>
          <PingSiteCommandButton siteId={siteId} />
        </SecurableComponent>
        <SecurableComponent
          permissions={[Permissions.ProsSiteManagementWrite]}
          showError={false}
        >
          <UploadLogsCommandButton siteId={siteId} />
        </SecurableComponent>
        <SecurableComponent
          permissions={[Permissions.ProsSiteManagementWrite]}
          showError={false}
        >
          <UpdateLogLevelCommandButton siteId={siteId} />
        </SecurableComponent>
      </Stack>

      <Heading variant="heading">Site Provisioning</Heading>
      <Stack horizontal wrap>
        <SecurableComponent
          permissions={[Permissions.ProsSiteManagementProvision]}
        >
          <DownloadSiteAgentCommandButton siteId={siteId} />
          <UpdateSiteAgentCommandButton siteId={siteId} />
          <CheckUpdateCommandButton siteId={siteId} />
          <InstallSiteAgentCommandButton siteId={siteId} />
          <UninstallSiteAgentCommandButton siteId={siteId} />
          <ModifyDatabaseCommandButton siteId={siteId} />
          <ProvisionFeatureCommandButton siteId={siteId} />
          <InstallPrerequisitesCommandButton siteId={siteId} />
          <UpdateSecretsCommandButton siteId={siteId} />
        </SecurableComponent>
      </Stack>

      <Heading variant="heading">Site Management</Heading>
      <Stack horizontal wrap>
        <SecurableComponent
          permissions={[Permissions.ProsSiteManagementWrite]}
          showError={false}
        >
          <StartSiteComponentCommandButton siteId={siteId} />
        </SecurableComponent>
        <SecurableComponent
          permissions={[Permissions.ProsSiteManagementWrite]}
          showError={false}
        >
          <StopSiteComponentCommandButton siteId={siteId} />
        </SecurableComponent>
        <SecurableComponent
          permissions={[Permissions.ProsSiteManagementWrite]}
          showError={false}
        >
          <RestartSiteComponentCommandButton siteId={siteId} />
        </SecurableComponent>
        <SecurableComponent
          permissions={[Permissions.ProsSiteManagementProvision]}
          showError={false}
        >
          <SnapshotTablesCommandButton siteId={siteId} />
        </SecurableComponent>
        <SecurableComponent
          permissions={[Permissions.ProsSiteManagementProvision]}
          showError={false}
        >
          <RecalculateAvailabilityCommandButton siteId={siteId} />
        </SecurableComponent>
        <SecurableComponent
          permissions={[Permissions.ProsSiteManagementProvision]}
          showError={false}
        >
          <DeleteEntityEventsSiteCommandButton siteId={siteId} />
        </SecurableComponent>
      </Stack>

      <Heading variant="heading">Schedule Commands</Heading>
      <Stack horizontal>
        <SecurableComponent
          permissions={[Permissions.ProsSiteManagementProvision]}
        >
          <SiteUpdateCommandButton siteId={siteId} />
        </SecurableComponent>
      </Stack>
      <CommandRunnerStatusBar />
    </CommandRunnerProvider>
  );
});
