import React from "react";

import { SectionTitle } from "@components/SectionTitle";
import {
  FieldDevice,
  FieldDeviceIdentifier
} from "@libs/api/gateways/field/field-ops-gateway.dtos";
import { useMostRecentFieldDeviceIdentifiers } from "@libs/api/gateways/field/field-ops-gateway.hooks";

import {
  IdentifiersFilter,
  IdentifiersFilterValues
} from "./IdentifiersFilter";
import { IdentifiersTable } from "./IdentifiersTable";

interface FieldDeviceIdentifiersProps {
  device: FieldDevice;
}

export const FieldDeviceIdentifiers: React.FC<FieldDeviceIdentifiersProps> = ({
  device
}) => {
  const identifiersQuery = useMostRecentFieldDeviceIdentifiers(device.id);
  const { data, isLoading, error } = identifiersQuery;
  const errorMessage = error?.message;

  return (
    <>
      <SectionTitle>Device Identifiers</SectionTitle>
      <IdentifiersFilter>
        {({ values }) => {
          return (
            <IdentifiersTable
              items={filterAndSortIdentifiers(data, values)}
              enableShimmer={isLoading}
              errorMessage={errorMessage}
            />
          );
        }}
      </IdentifiersFilter>
    </>
  );
};

const filterAndSortIdentifiers = (
  identifiers: FieldDeviceIdentifier[] | undefined,
  filter: IdentifiersFilterValues
): FieldDeviceIdentifier[] => {
  if (!identifiers) return [];

  return identifiers
    .filter(d => {
      const containsAuthenticatedFilter =
        filter.authenticated.length === 0
          ? true
          : filter.authenticated.filter(
              filterVal => String(d.authenticated) === filterVal
            ).length > 0;

      const noFiltersApplied = filter.authenticated.length === 0;

      if (containsAuthenticatedFilter || noFiltersApplied) {
        return (
          filter.name === "" ||
          d.identifierType.toLowerCase().indexOf(filter.name.toLowerCase()) >=
            0 ||
          (d.identifierValue &&
            d.identifierValue
              .toLowerCase()
              .indexOf(filter.name.toLowerCase()) >= 0)
        );
      }

      return false;
    })
    .sort((a: FieldDeviceIdentifier, b: FieldDeviceIdentifier) => {
      return a.identifierType < b.identifierType ? 1 : -1;
    });
};
