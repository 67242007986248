import { UseFormReturn } from "react-hook-form";

import { MessageBar, MessageBarType, Stack, useTheme } from "@bps/fluent-ui";
import { HttpError } from "@bps/http-client";
import { FormTextField } from "@components/form/fields/FormTextField";
import { Form } from "@components/form/Form";
import { SelectorDto } from "@libs/api/gateways/field/field-ops-gateway.dtos";

import { SelectorFormValues } from "./formUtils";
import { OperatorOptions, SelectorRuleTable } from "./SelectorRuleTable";
import { Validator } from "@components/form/validation/Validator";
import { ValidationSchema } from "@bps/utils";

const validator = new Validator<SelectorFormValues>();
const schema: ValidationSchema<SelectorFormValues> = {
  rules: validator.array<"rules">().itemArray({
    value: validator
      .string()
      .maxLength(1000, { message: "Maximum length is 1000 characters" })
  })
};

export interface SelectorFormProps {
  onSubmit: (
    values: SelectorFormValues,
    form: UseFormReturn<SelectorFormValues>
  ) => Promise<void>;
  onDismiss: () => void;
  selector?: SelectorDto;
  error?: HttpError | null;
}

export const SelectorForm: React.FC<SelectorFormProps> = ({
  onSubmit,
  onDismiss,
  selector,
  error
}) => {
  const theme = useTheme();

  const defaultValues: SelectorFormValues = {
    description: selector?.description ?? "",
    name: selector?.name ?? "",
    rules: selector?.rules.map(rule => {
      return {
        attributeType: rule.attributeType,
        comparisonOperator: rule.comparisonOperator,
        id: rule.id,
        value: rule.value,
        valueDataType: rule.valueDataType
      };
    }) ?? [
      {
        attributeType: "",
        value: "",
        comparisonOperator: OperatorOptions[0].key, // default operator is "="
        id: crypto.randomUUID(),
        valueDataType: "STRING"
      }
    ]
  };

  return (
    <Form<SelectorFormValues>
      onSubmit={onSubmit}
      validate={values => validator.validateWithParse(values, schema)}
      defaultValues={defaultValues}
      submitButtonText={selector === undefined ? "Create" : "Update"}
      cancelButtonText="Cancel"
      showCancelButton={true}
      onCancel={onDismiss}
    >
      {error && (
        <MessageBar messageBarType={MessageBarType.error}>
          {error?.detail as string}
        </MessageBar>
      )}

      <Stack tokens={{ childrenGap: theme.spacing.s1 }}>
        <FormTextField
          label="Selector Name"
          required
          name="name"
          styles={{ root: { width: 250 } }}
        />
        <FormTextField
          label="Description"
          name="description"
          styles={{ root: { width: 250 } }}
        />

        <SelectorRuleTable />
      </Stack>
    </Form>
  );
};
