import { PremSettingFormValues } from "modules/tenants/sections/bp-premier/prem-settings/prem-settings-table";
import { UseFormReturn } from "react-hook-form";

import { confirm, Stack, Text } from "@bps/fluent-ui";
import {
  PremSettings,
  PremSettingsUpdate
} from "@libs/api/gateways/tg/tg-ops-gateway.dtos";

interface PremSettingsConfirmProps {
  changeState: (
    values: PremSettingFormValues,
    form: UseFormReturn<PremSettingFormValues>,
    changedValues: PremSettingsUpdate[]
  ) => Promise<any>;
  values: PremSettingFormValues;
  form: UseFormReturn<PremSettingFormValues>;
  premSettings: PremSettings[];
}

export const confirmPremSettings = async ({
  changeState,
  values,
  form,
  premSettings
}: PremSettingsConfirmProps) => {
  const dialogLabels = {
    title: "Update Premier Settings",
    content: "Please confirm the new setting values you are updating below:",
    buttons: {
      yes: "Update",
      no: "Cancel"
    }
  };

  const { updateValues } = values;
  const changedValues = updateValues.filter(value =>
    premSettings.find(
      setting =>
        setting.settingName === value.settingName &&
        setting.settingValue !== value.settingValue
    )
  );

  const isConfirm = await confirm({
    cancelButtonProps: {
      text: dialogLabels.buttons.no
    },
    confirmButtonProps: {
      text: dialogLabels.buttons.yes
    },
    dialogContentProps: {
      title: dialogLabels.title,
      subText: dialogLabels.content
    },
    minWidth: "550px",
    children: (
      <Stack>
        {changedValues.map(premSetting => {
          return (
            <Text key={premSetting.settingName}>
              {premSetting.settingName}: {premSetting.settingValue}
            </Text>
          );
        })}
      </Stack>
    )
  });
  if (isConfirm) {
    changeState(values, form, changedValues);
  }
};
