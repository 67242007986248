import React, { useState } from "react";

import { newGuid } from "@bps/utils";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { withPermissions } from "@components/withPermissions";
import { MobileAppSettings } from "@libs/api/gateways/best-health/account/best-health-account-ops-gateway.dtos";
import {
  useGetMobileAppSettings,
  usePostMobileAppSettings,
  usePutMobileAppSettings
} from "@libs/api/gateways/best-health/account/best-health-account-ops-gateway.hooks";
import {
  MAINTENANCE_MODE,
  MAINTENANCE_MODE_DESCRIPTION,
  MINIMUM_VERSION_NUMBER,
  VERSION_UPGRADE_MESSAGES
} from "@libs/api/gateways/bp-mobile/bp-mobile.constants";
import { Permissions } from "@libs/permissions/permissions.enum";

import { MobileAppForm, MobileAppFormOnSubmit } from "./MobileAppForm";

const BhMobileAppBase: React.FC = () => {
  const queryGet = useGetMobileAppSettings();
  const queryUpdate = usePutMobileAppSettings();
  const queryPost = usePostMobileAppSettings();

  const [isLoading, setIsLoading] = useState<boolean>();

  const onSubmit: MobileAppFormOnSubmit = async values => {
    setIsLoading(true);
    try {
      const mobileAppSettingsUpdates = [
        {
          mobileAppSettingsId: values.maintenanceModeId,
          settingName: MAINTENANCE_MODE,
          settingValue: values.maintenanceModeEnabled ? "true" : "false"
        },
        {
          mobileAppSettingsId: values.maintenanceModeDescriptionId,
          settingName: MAINTENANCE_MODE_DESCRIPTION,
          settingValue: values.maintenanceModeDescription
        },
        {
          mobileAppSettingsId: values.versionUpgradeMessagesId,
          settingName: VERSION_UPGRADE_MESSAGES,
          settingValue: JSON.stringify(values.appVersions)
        },
        {
          mobileAppSettingsId: values.minimumVersionNumberId,
          settingName: MINIMUM_VERSION_NUMBER,
          settingValue: values.minimumVersionNumber
        }
      ];

      for (const update of mobileAppSettingsUpdates) {
        if (update.settingValue !== undefined) {
          if (update.mobileAppSettingsId) {
            queryUpdate.mutate(update as MobileAppSettings);
          } else {
            queryPost.mutate({
              ...update,
              mobileAppSettingsId: newGuid()
            } as MobileAppSettings);
          }
        }
      }
    } catch (e) {
      throw new Error(e);
    }
    setIsLoading(false);
  };

  return (
    <QueryStateIndicator<MobileAppSettings[]> {...queryGet}>
      {data => (
        <MobileAppForm
          onSubmit={onSubmit}
          mobileAppSettings={data}
          isLoading={isLoading}
        />
      )}
    </QueryStateIndicator>
  );
};

export const BhMobileApp = withPermissions(
  BhMobileAppBase,
  [Permissions.BhMobileAppRead, Permissions.BhMobileAppWrite],
  "or"
);
