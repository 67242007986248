import { useMemo } from "react";
import { UseFormReturn } from "react-hook-form";

import { Dialog, IDialogProps } from "@bps/fluent-ui";
import { HttpError } from "@bps/http-client";
import { useCreateSelector } from "@libs/api/gateways/field/field-ops-gateway.hooks";

import { SelectorFormValues } from "./formUtils";
import { SelectorForm } from "./SelectorForm";

interface NewSelectorDialogProps extends IDialogProps {
  onDismiss: () => void;
  formError: HttpError | null;
  setFormError: React.Dispatch<React.SetStateAction<HttpError | null>>;
}

export const NewSelectorDialog: React.FC<NewSelectorDialogProps> = ({
  onDismiss,
  formError,
  setFormError,
  ...props
}) => {
  const { mutateAsync: createSelector, error } = useCreateSelector();

  useMemo(() => {
    setFormError(error);
  }, [error, setFormError]);

  const onSubmit = async (
    values: SelectorFormValues,
    form: UseFormReturn<SelectorFormValues>
  ) => {
    const { rules, ...rest } = values;

    await createSelector({
      ...rest,
      rules: rules.map(rule => {
        // Drop frontend id from rule.
        return {
          attributeType: rule.attributeType,
          comparisonOperator: rule.comparisonOperator,
          value: rule.value,
          valueDataType: rule.valueDataType
        };
      })
    });

    // Reset form and dismiss model.
    onDismiss();
    setFormError(null); // reset form error message
    form.reset(values);
  };

  return (
    <Dialog
      dialogContentProps={{
        showCloseButton: true,
        title: "Create Selector"
      }}
      minWidth="60%"
      onDismiss={onDismiss}
      {...props}
    >
      <SelectorForm
        onSubmit={onSubmit}
        onDismiss={onDismiss}
        error={formError}
      />
    </Dialog>
  );
};
