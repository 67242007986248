import { FormSpinNumberInput } from "@components/form/fields/FormSpinNumberInput";
import { FormTextField } from "@components/form/fields/FormTextField";
import React, { FunctionComponent } from "react";

interface MessageCreditsEventFormFieldsProps {
  // isNewMessageCreditsEvent: boolean;
  tenantId: string;
}

export const MessageCreditsEventFormFields: FunctionComponent<MessageCreditsEventFormFieldsProps> = () => {
  return (
    <>
      <FormSpinNumberInput label="Units" name="units" required />
      <FormTextField label="Details" name="details" required />
    </>
  );
};
