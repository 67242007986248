import { CommandRunnerProvider } from "../../_shared-models/CommandRunner";
import { withPermissions } from "@components/withPermissions";
import { CopyableGuid } from "@components/CopyableText";
import { SectionTitle } from "@components/SectionTitle";
import { Permissions } from "@libs/permissions/permissions.enum";
import { TenantDetailsTable } from "modules/tenants/sections/plt/details/components/TenantDetailsTable";
import { TenantResourcesTable } from "modules/tenants/sections/plt/details/components/TenantResourcesTable";
import { EncryptTenantResourcesButton } from "modules/tenants/sections/plt/EncryptTenantResourcesButton";
import { TenantSectionProps } from "modules/tenants/sections/plt/types";
import { ScrollablePane, Stack, Text, useTheme } from "@bps/fluent-ui";
import { ChangeIsInActiveButton } from "./components/ChangeIsInActiveButton";
import { ChangeIsTemporaryButton } from "./components/ChangeIsTemporaryButton";
import { CreateChildTenantDialog } from "./components/CreateChildTenantDialog";
import { TenantEditDetailsFormDialog } from "./components/TenantEditDetailsFormDialog";
import { ApplicationType } from "@libs/api/gateways/plt/plt-gateway.dtos";

const TenantDetailsBase = ({ tenant }: TenantSectionProps) => {
  const theme = useTheme();

  return (
    <Stack
      verticalFill
      styles={{ root: { padding: theme.spacing.s1 } }}
      tokens={{ childrenGap: theme.spacing.l2 }}
    >
      <Stack tokens={{ childrenGap: 16 }}>
        <SectionTitle>{tenant.name}</SectionTitle>
        <CopyableGuid
          value={tenant.id}
          variant="small"
          color={theme.palette.themeSecondary}
        />
        <Stack horizontal tokens={{ childrenGap: theme.spacing.s1 }}>
          <TenantEditDetailsFormDialog tenant={tenant} />
          {tenant.application === ApplicationType.CAM && (
            <CreateChildTenantDialog tenant={tenant} />
          )}
        </Stack>
      </Stack>

      <Stack
        verticalFill
        styles={{ root: { position: "relative", width: "100%" } }}
      >
        <ScrollablePane>
          <Stack tokens={{ childrenGap: theme.spacing.l2 }}>
            <Stack grow>
              <TenantDetailsTable tenant={tenant} />
            </Stack>
            <Stack>
              <Text variant="xLarge">Resources</Text>
              <TenantResourcesTable tenant={tenant} />
            </Stack>
            <Stack horizontal tokens={{ childrenGap: theme.spacing.s1 }}>
              <CommandRunnerProvider>
                <EncryptTenantResourcesButton {...tenant} />
              </CommandRunnerProvider>
              <CommandRunnerProvider>
                <ChangeIsTemporaryButton {...tenant} />
              </CommandRunnerProvider>
              <CommandRunnerProvider>
                <ChangeIsInActiveButton {...tenant} />
              </CommandRunnerProvider>
            </Stack>
          </Stack>
        </ScrollablePane>
      </Stack>
    </Stack>
  );
};

export const TenantDetails = withPermissions(
  TenantDetailsBase,
  [
    Permissions.PltCatalogTenantRead,
    Permissions.PltCatalogTenantWrite,
    Permissions.PltCatalogOpsWrite
  ],
  "or"
);
