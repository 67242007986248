import {
  FontIcon,
  Stack,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { FunctionComponent } from "react";
import { InvoiceStatus } from "@libs/api/gateways/plt/plt-gateway.dtos";

interface InvoiceStatusBadgeProps {
  status?: InvoiceStatus;
  failedMessage?: string;
}
export const InvoiceStatusBadge: FunctionComponent<InvoiceStatusBadgeProps> = ({
  status,
  failedMessage
}) => {
  const theme = useTheme();
  const getColor = () => {
    switch (status) {
      case InvoiceStatus.Paid:
        return TextBadgeColor.green;
      case InvoiceStatus.Void:
      case InvoiceStatus.Uncollectible:
        return TextBadgeColor.red;
      default:
        return TextBadgeColor.yellow;
    }
  };
  return (
    <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign="center">
      <TextBadge
        className="cam-InvoiceStatusBadge"
        badgeSize={TextBadgeSize.small}
        badgeColor={getColor()}
        styles={{ root: { textTransform: "capitalize", maxWidth: 100 } }}
      >
        {status}
      </TextBadge>

      {failedMessage && (
        <TooltipHost
          content={failedMessage}
          styles={{ root: { display: "flex" } }}
          calloutProps={{
            styles: {
              beak: { backgroundColor: theme.semanticColors.errorBackground },
              beakCurtain: {
                backgroundColor: theme.semanticColors.errorBackground
              }
            }
          }}
          tooltipProps={{
            styles: {
              content: {
                backgroundColor: theme.semanticColors.errorBackground,
                color: theme.palette.redDark
              }
            }
          }}
        >
          <FontIcon
            iconName="Info"
            styles={{
              root: {
                fontSize: 18,
                color: theme.semanticColors.errorIcon,
                cursor: "pointer"
              }
            }}
          />
        </TooltipHost>
      )}
    </Stack>
  );
};
