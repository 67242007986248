import { Form } from "@components/form/Form";
import { FormTextField } from "@components/form/fields/FormTextField";

import { Dialog, Heading } from "@bps/fluent-ui";
import { FormCheckbox } from "@components/form/fields/FormCheckbox";
import { FC } from "react";
import { useBatchAddNotification } from "@libs/api/gateways/plt-notifications/plt-notifications-gateway.hooks";
import {
  AddNotificationDto,
  NotificationPriority,
  NotificationType
} from "@libs/api/gateways/plt-notifications/plt-notifications-gateway.dtos";
import { Validator } from "@components/form/validation/Validator";

interface SendNotificationFormValues {
  title: string;
  description?: string;
  highPriority: boolean;
}

interface SendNotificationsDialogProps {
  tenantId: string;
  usersIds: string[];
  onDismiss: () => void;
}

const sendNotificationValidator = new Validator<SendNotificationFormValues>(
  validator => ({
    title: validator.string().required()
  })
);

export const SendNotificationsDialog: FC<SendNotificationsDialogProps> = ({
  tenantId,
  usersIds,
  onDismiss
}) => {
  const { mutateAsync, error } = useBatchAddNotification();

  const onSubmit = async (values: SendNotificationFormValues) => {
    if (!usersIds.length) return;

    const dtos: AddNotificationDto[] = usersIds.map(userId => {
      const notification: AddNotificationDto = {
        tenantId,
        userId,
        title: values.title,
        description: values.description,
        type: NotificationType.System,
        priority: values.highPriority
          ? NotificationPriority.High
          : NotificationPriority.Normal
      };

      return notification;
    });

    try {
      await mutateAsync(dtos);
    } catch (e) {
      return e;
    }

    onDismiss();
  };

  return (
    <Dialog
      hidden={false}
      onDismiss={onDismiss}
      dialogContentProps={{ showCloseButton: true }}
    >
      <Form<SendNotificationFormValues>
        defaultValues={{
          title: "",
          description: "",
          highPriority: false
        }}
        onSubmit={onSubmit}
        showCancelButton
        onCancel={onDismiss}
        error={error}
        validate={sendNotificationValidator.validateWithParse}
      >
        <Heading variant="section-heading">
          Send notification to {usersIds.length} users
        </Heading>
        <FormTextField label="Title" name="title" required />
        <FormTextField label="Description" name="description" />
        <FormCheckbox label="High priority" name="highPriority" />
      </Form>
    </Dialog>
  );
};
