import { FunctionComponent } from "react";

import { FilterBar } from "@components/filter-bar/FilterBar";
import { FilterBarProps, Item } from "@components/filter-bar/FilterBar.types";

interface RedirectsFilterProps
  extends Pick<FilterBarProps<RedirectsFilterValues>, "children"> {}
interface RedirectsFilterValues {
  linkId?: string;
  hasPin?: boolean;
}
export const RedirectsFilter: FunctionComponent<RedirectsFilterProps> = props => {
  const items: Item<RedirectsFilterValues>[] = [
    {
      name: "linkId",
      type: "searchBox",
      stickItem: true,
      props: {
        placeholder: "Search by link id",
        removeSpecialCharacters: false
      }
    },
    {
      name: "hasPin",
      type: "toggle",
      props: {
        label: "Has Pin"
      }
    }
  ];
  return <FilterBar {...props} items={items} />;
};
