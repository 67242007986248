import { DataTable, DataTableColumn } from "@components/tables/DataTable";
import React from "react";

import {
  ShimmeredDetailsListProps,
  TextBadge,
  TextBadgeColor
} from "@bps/fluent-ui";
import { DATE_FORMATS } from "@bps/utils";
import { useApprovedComponentVersionsQuery } from "@libs/api/gateways/sia/sia-ops-gateway.hooks";
import { SiteComponentVersionResponse } from "@libs/api/gateways/sia/sia-ops-gateway.dtos";

interface SysComManagerTableProps
  extends Omit<ShimmeredDetailsListProps, "items"> {
  items: SiteComponentVersionResponse[];
}

export const SysComVersionsTable: React.FC<SysComManagerTableProps> = props => {
  const { data: approvedVersions } = useApprovedComponentVersionsQuery();

  const columns: DataTableColumn<SiteComponentVersionResponse>[] = [
    {
      key: "version",
      name: "Version",
      minWidth: 200,
      maxWidth: 400,
      onRender: (component: SiteComponentVersionResponse) => component.version
    },
    {
      key: "createdDate",
      name: "Created Date",
      minWidth: 200,

      isSorted: true,
      isSortedDescending: true,
      sort: true,
      onRender: (component: SiteComponentVersionResponse) =>
        component.createdDate.toFormat(
          DATE_FORMATS.LONG_DATE_FORMAT_MONTH_AHEAD_CUT
        )
    },
    {
      key: "approved",
      name: "Approved",
      minWidth: 300,
      maxWidth: 400,
      onRender: (component: SiteComponentVersionResponse) => {
        const approvedVersion = approvedVersions?.find(
          ap => ap.componentVersion === component.version
        );

        if (approvedVersion) {
          return (
            <TextBadge badgeColor={TextBadgeColor.green}> Approved</TextBadge>
          );
        }

        return (
          <TextBadge badgeColor={TextBadgeColor.red}> Not Approved</TextBadge>
        );
      }
    },
    {
      key: "approvedBy",
      name: "Approved By",
      minWidth: 200,
      maxWidth: 400,
      onRender: (component: SiteComponentVersionResponse) => {
        const approvedVersion = approvedVersions?.find(
          ap => ap.componentVersion === component.version
        );

        if (approvedVersion) {
          return approvedVersion.createdBy;
        }
      }
    },
    {
      key: "approvalDate",
      name: "Approval Date",
      minWidth: 300,
      maxWidth: 200,
      onRender: (component: SiteComponentVersionResponse) => {
        const approvedVersion = approvedVersions?.find(
          ap => ap.componentVersion === component.version
        );

        if (approvedVersion) {
          return approvedVersion.createdDate.toFormat(
            DATE_FORMATS.LONG_DATE_FORMAT_MONTH_AHEAD_CUT
          );
        }
      }
    }
  ];

  return (
    <DataTable
      shimmerLines={10}
      columns={columns}
      onShouldVirtualize={() => true}
      {...props}
    />
  );
};
