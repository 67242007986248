import { FunctionComponent } from "react";
import {
  DetailsList,
  DetailsRow,
  FontIcon,
  IColumn,
  ScrollablePane,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import {
  ProductChildTenant,
  SubscriptionPreviewDto
} from "@libs/api/gateways/plt/plt-gateway.dtos";

interface PreviewSubscriptionListProps {
  previewData: SubscriptionPreviewDto;
}

export const PreviewSubscriptionList: FunctionComponent<PreviewSubscriptionListProps> = ({
  previewData
}) => {
  const theme = useTheme();

  const columns: IColumn[] = [
    {
      key: "child_tenant",
      name: "Name",
      minWidth: 100,
      onRender: (item: ProductChildTenant) => item.childTenantName
    },
    {
      key: "before",
      name: "Before",
      minWidth: 80,
      onRender: (item: ProductChildTenant) =>
        item?.previousScheduledQuantity ?? 0
    },
    {
      key: "after",
      name: "After",
      minWidth: 80,
      onRender: (item: ProductChildTenant) => item?.scheduledQuantity ?? 0
    },
    {
      key: "diff",
      name: "",
      minWidth: 60,
      onRender: (item: ProductChildTenant) => {
        const diff =
          (item?.scheduledQuantity ?? 0) -
          (item?.previousScheduledQuantity ?? 0);

        return (
          <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign="center">
            <Text>{Math.abs(diff)}</Text>
            <FontIcon
              iconName={diff > 0 ? "TriangleSolidUp12" : "TriangleSolidDown12"}
              styles={{
                root: {
                  fontWeight: 600,
                  color:
                    diff > 0
                      ? theme.semanticColors.successIcon
                      : theme.semanticColors.errorIcon
                }
              }}
            />
          </Stack>
        );
      }
    }
  ];

  return (
    <Stack styles={{ root: { position: "relative", height: "100%" } }}>
      <ScrollablePane>
        <DetailsList
          stickyHeader
          items={previewData.items.filter(
            item =>
              item.childTenants.filter(
                ct =>
                  (ct.scheduledQuantity ?? 0) !==
                  (ct.previousScheduledQuantity ?? 0)
              ).length
          )}
          compact
          columns={columns}
          onRenderRow={(props, defaultRender) => {
            if (!props || !defaultRender) return null;

            return (
              <>
                <Stack
                  key={props.item.salesProductId}
                  styles={{
                    root: {
                      backgroundColor: theme.palette.neutralLight,
                      padding: 6
                    }
                  }}
                >
                  <Text bold>{props.item.salesProductName}</Text>
                </Stack>
                {props.item.childTenants
                  .filter(
                    ct =>
                      (ct.scheduledQuantity ?? 0) !==
                      (ct.previousScheduledQuantity ?? 0)
                  )
                  .map((ct: ProductChildTenant) => (
                    <DetailsRow
                      key={props.item.salesProductId + ct.childTenantId}
                      {...props}
                      item={ct}
                    />
                  ))}
              </>
            );
          }}
        />
      </ScrollablePane>
    </Stack>
  );
};
