import { DataTable, DataTableColumn } from "@components/tables/DataTable";

import { DateTime } from "@bps/utils";
import { EntitySequence } from "@libs/api/gateways/tg/tg-ops-gateway.dtos";

interface Props {
  entitySequences: EntitySequence[];
}

const TimeCell = ({ time }: { time?: DateTime }) => (
  <span>{time?.toFormat("LL/dd/yyyy, h:mm a") ?? "--"}</span>
);

export const EntitySequenceStateTable = ({ entitySequences }: Props) => {
  const columns: DataTableColumn<EntitySequence>[] = [
    {
      key: "entityType",
      name: "Entity Type",
      minWidth: 280,
      maxWidth: 350,
      isSorted: true,
      sort: true
    },
    {
      key: "sequenceNo",
      name: "Sequence No",
      minWidth: 120,
      maxWidth: 150,
      sort: true
    },
    {
      key: "updatedDate",
      name: "Updated",
      minWidth: 200,
      maxWidth: 220,
      onRender: (item: EntitySequence) => <TimeCell time={item.updatedDate} />
    }
  ];

  const getKey = (entitySequence: EntitySequence) => entitySequence.entityType;

  return (
    <DataTable
      items={entitySequences}
      columns={columns}
      getKey={getKey}
      onShouldVirtualize={() => false}
    />
  );
};
