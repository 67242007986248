import { SubscriptionItemProducts } from "./SubscriptionItems.types";
import {
  CustomerProductsSubscription,
  Tenant
} from "@libs/api/gateways/plt/plt-gateway.dtos";
import { groupBy } from "@bps/utils";

const getMissingChildTenants = (
  subscription: CustomerProductsSubscription,
  childTenants: Tenant[]
) =>
  childTenants.filter(
    ct =>
      !subscription?.subscriptionItemList
        .map(c => c.childTenantId)
        .includes(ct.id)
  );

export const getSubscriptionsProducts = (
  subscription: CustomerProductsSubscription,
  childTenants: Tenant[]
): SubscriptionItemProducts[] => {
  const missingChildTenants = getMissingChildTenants(
    subscription,
    childTenants
  );

  return groupBy(subscription.subscriptionItemList, item => item.productId).map(
    ([productId, items]) => {
      const existingChildTenants = items.map(item => ({
        quantity: item.quantity ?? 0,
        childTenantId: item.childTenantId,
        childTenantName: item.childTenantName,
        scheduledQuantity: item.scheduledQuantity ?? 0,
        salesProductId: productId
      }));

      const childTenants = [
        ...existingChildTenants,
        ...missingChildTenants.map(t => ({
          quantity: 0,
          childTenantId: t.id,
          childTenantName: t.name,
          scheduledQuantity: 0,
          salesProductId: productId
        }))
      ];

      const scheduledQuantity = items.reduce(
        (acc, item) => acc + (item.scheduledQuantity ?? 0),
        0
      );

      const quantity = items.reduce(
        (acc, item) => acc + (item.quantity ?? 0),
        0
      );

      return {
        salesProductId: productId,
        salesProductFamily: items[0].productFamily,
        salesProductName: items[0]?.displayName,
        quantity,
        scheduledQuantity,
        childTenants
      };
    }
  );
};
