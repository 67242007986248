import { Dialog, IDialogProps } from "@bps/fluent-ui";
import { BpMobileNotification } from "@libs/api/gateways/bp-mobile/bp-mobile.dtos";
import { useUpsertNotification } from "@libs/api/gateways/bp-mobile/bp-mobile.hooks";

import { convertToDateTime } from "../utils";
import { NotificationForm, NotificationFormValues } from "./NotificationForm";

interface NotificationDialogProps extends IDialogProps {
  notification: BpMobileNotification | undefined;
  onDismiss: () => void;
}

export const NotificationDialog: React.FC<NotificationDialogProps> = ({
  notification,
  onDismiss,
  ...props
}) => {
  const { mutateAsync: upsertNotification } = useUpsertNotification();

  const onSubmit = async (formData: NotificationFormValues) => {
    const {
      outageMessage,
      notificationTimeStartUtc,
      notificationTimeEndUtc,
      notificationDateStartUtc,
      notificationDateEndUtc
    } = formData;

    const startTime = convertToDateTime(
      notificationDateStartUtc!,
      notificationTimeStartUtc!
    );

    const endTime = convertToDateTime(
      notificationDateEndUtc!,
      notificationTimeEndUtc!
    );

    const partitionKey = notification?.partitionKey
      ? notification.partitionKey
      : startTime
          .toISO()
          .replace(/[-:T.Z]/g, "")
          .slice(0, 14);

    const rowKey = notification?.rowKey
      ? notification.rowKey
      : endTime
          .toISO()
          .replace(/[-:T.Z]/g, "")
          .slice(0, 14);

    await upsertNotification({
      outageMessage,
      partitionKey,
      rowKey,
      startTime: startTime.toISO(),
      endTime: endTime.toISO(),
      dateUtc: new Date().toISOString()
    });

    onDismiss();
  };

  const selectedNotification: BpMobileNotification = notification
    ? notification
    : {};

  return (
    <Dialog
      dialogContentProps={{
        showCloseButton: true,
        title: notification?.partitionKey
          ? "Edit Notification"
          : "Add Notification"
      }}
      onDismiss={onDismiss}
      minWidth={500}
      {...props}
    >
      <NotificationForm
        notification={selectedNotification}
        onSubmit={onSubmit}
      />
    </Dialog>
  );
};
