import React, { useState } from "react";
import {
  IColumn,
  FontIcon,
  Link,
  NativeList,
  ScrollablePane,
  Stack,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { DATE_FORMATS } from "@bps/utils";
import { useTabsNavContext } from "@components/tabs-nav";
import { ShimmeredDetailsList } from "@components/tables/ShimmeredDetailsList";
import { ScrollListener } from "@components/tables/ScrollListener";
import { useTenantsPltQuery } from "@libs/api/gateways/plt/plt-gateway.hooks";
import {
  Tenant,
  TenantSearchArgs
} from "@libs/api/gateways/plt/plt-gateway.dtos";
import { getPagesResult } from "@libs/paging/paging.utils";
import { TenantsTabs } from "../tenants-tabs.constants";
import { TenantsFilter } from "./TenantsFilter";
import { PltTenantApplicationBadge } from "../../_shared-blocks/PltTenantApplicationBadge";

export const TenantSelector = () => {
  const theme = useTheme();
  const { addTab } = useTabsNavContext();

  const [args, setArgs] = useState<TenantSearchArgs>({
    isInactive: false,
    name: "",
    tenantIds: [],
    crmId: ""
  });

  const [sortField, setSortField] = useState<TenantSearchArgs["sortField"]>();
  const [sortAscending, setSortAscending] = useState<
    TenantSearchArgs["sortAscending"]
  >();

  const {
    data,
    hasNextPage,
    fetchNextPage,
    isLoading,
    error
  } = useTenantsPltQuery(
    {
      ...args,
      sortAscending,
      sortField
    },
    { refetchOnMount: "always" }
  );

  const handleScrolledToBottom = async () => {
    if (hasNextPage) {
      await fetchNextPage();
    }
  };

  const tenants = getPagesResult(data);

  const sortIconsStyles = {
    sortIcon: { color: theme.palette.themePrimary, fontWeight: 600 }
  } as any;

  const onTenantSelected = data => {
    addTab({
      id: data.id,
      name: data.name,
      sectionId: TenantsTabs.Platform.Details.id
    });
  };

  const columns: IColumn[] = [
    {
      key: "name",
      name: "Name",
      minWidth: 200,
      isSorted: true,
      isSortedDescending: sortField === "Name" && !sortAscending,
      onColumnClick: (_evt, column: IColumn) => {
        setSortField("Name");
        setSortAscending(column.isSortedDescending);
      },
      styles: sortIconsStyles,
      onRender: (tenant: Tenant) => (
        <Stack>
          <Link onClick={() => onTenantSelected(tenant)}>{tenant.name}</Link>
          <Text variant="xSmall">{tenant.id}</Text>
        </Stack>
      )
    },
    {
      key: "cmrId",
      name: "CrmId",
      minWidth: 100,
      onRender: (tenant: Tenant) => (
        <Stack>
          <Link onClick={() => onTenantSelected(tenant)}>{tenant.crmId}</Link>
        </Stack>
      )
    },
    {
      key: "application",
      name: "Application",
      minWidth: 170,
      isSorted: true,
      isSortedDescending: sortField === "ApplicationCode" && !sortAscending,
      onColumnClick: (_evt, column: IColumn) => {
        setSortField("ApplicationCode");
        setSortAscending(column.isSortedDescending);
      },
      styles: sortIconsStyles,
      onRender: (item: Tenant) => (
        <PltTenantApplicationBadge applicationCode={item.application} />
      )
    },
    {
      key: "country",
      name: "Country",
      minWidth: 75,
      isSorted: true,
      isSortedDescending: sortField === "Country" && !sortAscending,
      onColumnClick: (_evt, column: IColumn) => {
        setSortField("Country");
        setSortAscending(column.isSortedDescending);
      },
      styles: sortIconsStyles,
      onRender: (tenant: Tenant) => tenant.country
    },
    {
      key: "permissions",
      name: "Permissions",
      minWidth: 85,
      onRender: (item: Tenant) => {
        if (!item.permissions?.length) {
          return "--";
        }
        return (
          <TooltipHost
            content={
              <NativeList>
                {item.permissions.map(perm => (
                  <ol key={perm}>{perm}</ol>
                ))}
              </NativeList>
            }
          >
            <TextBadge
              badgeSize={TextBadgeSize.small}
              badgeColor={TextBadgeColor.yellow}
              horizontal
              horizontalAlign="center"
            >
              {item.permissions?.length}
              <FontIcon
                iconName="Info"
                styles={{ root: { fontSize: 10, paddingLeft: 4 } }}
              />
            </TextBadge>
          </TooltipHost>
        );
      }
    },
    {
      key: "resources",
      name: "Resources",
      minWidth: 85,
      onRender: (item: Tenant) => (item.resources?.length || "--").toString()
    },
    {
      key: "updated",
      name: "Last Updated",
      minWidth: 110,
      isSorted: true,
      isSortedDescending: sortField === "UpdatedDate" && !sortAscending,
      onColumnClick: (_evt, column: IColumn) => {
        setSortField("UpdatedDate");
        setSortAscending(column.isSortedDescending);
      },
      styles: sortIconsStyles,
      onRender: ({ changeLog }: Tenant) =>
        (changeLog.updatedDate ?? changeLog.createdDate).toFormat(
          DATE_FORMATS.DAY_DEFAULT_FORMAT
        )
    },
    {
      key: "Active",
      name: "Status",
      minWidth: 60,
      isSorted: true,
      isSortedDescending: sortField === "IsInactive" && !sortAscending,
      onColumnClick: (_evt, column: IColumn) => {
        setSortField("IsInactive");
        setSortAscending(column.isSortedDescending);
      },
      styles: sortIconsStyles,
      onRender: (item: Tenant) => {
        return (
          <TextBadge
            badgeSize={TextBadgeSize.small}
            badgeColor={
              item.isInactive ? TextBadgeColor.red : TextBadgeColor.green
            }
          >
            {item.isInactive ? "Inactive" : "Active"}
          </TextBadge>
        );
      }
    },
    {
      key: "isTemporary",
      name: "Is Temp",
      minWidth: 70,
      isSorted: true,
      isSortedDescending: sortField === "IsTemporary" && !sortAscending,
      onColumnClick: (_evt, column: IColumn) => {
        setSortField("IsTemporary");
        setSortAscending(column.isSortedDescending);
      },
      styles: sortIconsStyles,
      onRender: (item: Tenant) => {
        return (
          <TextBadge
            badgeSize={TextBadgeSize.small}
            badgeColor={
              item.isTemporary ? TextBadgeColor.red : TextBadgeColor.green
            }
          >
            {item.isTemporary ? "Yes" : "No"}
          </TextBadge>
        );
      }
    },
    {
      key: "fieldManandgment",
      name: "Fieldman",
      minWidth: 75,
      onRender: (item: Tenant) => {
        return (
          <TextBadge
            badgeSize={TextBadgeSize.small}
            badgeColor={
              !item.siteAuthorityFieldDeviceId
                ? TextBadgeColor.red
                : TextBadgeColor.green
            }
          >
            {item.siteAuthorityFieldDeviceId ? "Yes" : "No"}
          </TextBadge>
        );
      }
    }
  ];

  return (
    <Stack
      verticalFill
      styles={{
        root: {
          overflow: "hidden",
          backgroundColor: theme.semanticColors.bodyBackground,
          padding: theme.spacing.s1
        }
      }}
    >
      <TenantsFilter setArgs={setArgs} />
      <Stack
        verticalFill
        styles={{
          root: {
            position: "relative",
            height: "100%",
            flex: "1 1 0",
            overflowY: "auto"
          }
        }}
      >
        <ScrollablePane>
          <ShimmeredDetailsList
            items={tenants}
            stickyHeader
            columns={columns}
            onShouldVirtualize={() => false}
            enableShimmer={isLoading}
            errorMessage={error?.message}
          />
          <ScrollListener
            hasNextPage={hasNextPage ?? false}
            onScrolledToBottom={handleScrolledToBottom}
          />
        </ScrollablePane>
      </Stack>
    </Stack>
  );
};
