// Assuming `useRootStore` is imported correctly from your store setup
import React, { useEffect } from "react";

import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { withPermissions } from "@components/withPermissions";
import {
  useGetBpMobileAppSettings,
  usePutBpMobileAppSettings
} from "@libs/api/gateways/bp-mobile/bp-mobile.hooks";
import { Permissions } from "@libs/permissions/permissions.enum";
import { useRootStore } from "@stores/StoresProvider";

import {
  BpMobileMaintenanceForm,
  BpMobileMaintenanceFormSubmitValues
} from "./BpMobileMaintenanceAppForm";

const BpMobileAppBase: React.FC = () => {
  const {
    data: settings,
    isLoading: isLoadingSettings
  } = useGetBpMobileAppSettings();

  const updateMutation = usePutBpMobileAppSettings();

  const { feedback } = useRootStore();

  // Effect to handle success feedback
  useEffect(() => {
    if (updateMutation.isSuccess) {
      feedback.success("Settings updated successfully!");
    }
  }, [updateMutation.isSuccess, feedback]);

  const onSubmit = async (values: BpMobileMaintenanceFormSubmitValues) => {
    updateMutation.mutate(values);
  };

  return (
    <>
      <QueryStateIndicator
        isLoading={isLoadingSettings}
        data={settings}
        noDataText="No settings available"
        allowNullOrUndefined={true}
      >
        {settings => (
          <BpMobileMaintenanceForm
            onSubmit={onSubmit}
            settings={settings}
            isLoading={isLoadingSettings || updateMutation.isLoading}
          />
        )}
      </QueryStateIndicator>
    </>
  );
};

export const BpMobileApp = withPermissions(
  BpMobileAppBase,
  [Permissions.BhMobileSettingsRead, Permissions.BhMobileSettingsWrite],
  "or"
);
