import { IdentityFilterField } from "modules/users/components/IdentityFilterField";
import { SelectedBox } from "modules/users/components/SelectedBox";
import React, { useCallback, useState } from "react";
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";

import {
  DefaultButton,
  PrimaryButton,
  Spinner,
  Stack,
  Text,
  TextField,
  useTheme
} from "@bps/fluent-ui";
import { isGUID } from "@bps/utils";

import { BpIdUsersSearchArgs } from "@libs/api/gateways/bp-id/bp-id.dtos";
import { ValidationMessages } from "@libs/validation/validation-messages.enum";
import { Validator } from "@components/form/validation/Validator";
import { validationResolver } from "@components/form/validation/validation-resolver";

type IdentityFilterValues = Partial<BpIdUsersSearchArgs>;

interface IdentityFilterProps {
  showProfile?: boolean;
  isLoading?: boolean;
  onSubmit: (values: Partial<IdentityFilterValues>) => void;
}

const validator = new Validator<IdentityFilterValues>(validator => ({
  email: validator.string().email({
    message: ValidationMessages.email
  }),
  mobile: validator.string().phone({ message: "Numbers only" }),
  objectId: validator
    .custom()
    .func<"objectId">(value =>
      isGUID(value ?? "") ? value : ValidationMessages.guid
    )
}));

export const IdentityFilter: React.FC<IdentityFilterProps> = ({
  isLoading,
  onSubmit,
  showProfile
}) => {
  const theme = useTheme();
  const { control, reset, handleSubmit, getValues } = useForm<
    BpIdUsersSearchArgs
  >({
    mode: "onChange",
    resolver: values => validationResolver(validator.validateWithParse, values)
  });

  const [selectedField, setSelectedField] = useState<
    keyof BpIdUsersSearchArgs
  >();

  const errorStyle = {
    color: theme.semanticColors.errorText
  };

  const onClear = () => {
    reset();
    setSelectedField(undefined);
  };

  const _onSubmit = useCallback<SubmitHandler<BpIdUsersSearchArgs>>(
    values => {
      let submitValues: Partial<BpIdUsersSearchArgs> = {};
      if (
        selectedField === "givenNamePrefix" ||
        selectedField === "surnamePrefix"
      ) {
        submitValues = {
          givenNamePrefix: values.givenNamePrefix,
          surnamePrefix: values.surnamePrefix
        };
      } else if (selectedField) {
        submitValues = { [selectedField]: values[selectedField] };
      }
      onSubmit(submitValues);
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedField]
  );

  const _onInvalid = useCallback<SubmitErrorHandler<BpIdUsersSearchArgs>>(
    errors => {
      if (!errors[selectedField as string]) {
        _onSubmit(getValues());
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedField]
  );

  return (
    <form onSubmit={handleSubmit(_onSubmit, _onInvalid)}>
      <Stack tokens={{ childrenGap: theme.spacing.m }}>
        <Stack
          horizontal
          horizontalAlign="space-between"
          tokens={{ childrenGap: theme.spacing.m }}
        >
          <Stack
            styles={{ root: { flex: 1 } }}
            tokens={{ childrenGap: theme.spacing.s2 }}
          >
            {showProfile && (
              <IdentityFilterField
                control={control}
                name="profileIdPrefix"
                tooltip={error => (
                  <Stack tokens={{ childrenGap: theme.spacing.s2 }}>
                    <Text styles={{ root: error && errorStyle }}>
                      {error?.message ||
                        "Enter the start of or a full profile id"}
                    </Text>
                    <Text
                      variant="xSmall"
                      styles={{ root: error && errorStyle }}
                    >
                      eg: 1efc937d-2bef-25...
                    </Text>
                  </Stack>
                )}
                render={({
                  field: { value, onChange },
                  fieldState: { error }
                }) => {
                  return (
                    <SelectedBox
                      isSelected={selectedField === "profileIdPrefix"}
                      hasError={!!error}
                    >
                      <TextField
                        iconProps={{
                          iconName: "Contact"
                        }}
                        prefix="#"
                        label="Profile Id"
                        autoComplete="off"
                        onClick={() => setSelectedField("profileIdPrefix")}
                        value={value || ""}
                        onChange={onChange}
                      />
                    </SelectedBox>
                  );
                }}
              />
            )}
            <IdentityFilterField
              control={control}
              name="objectId"
              tooltip={error => (
                <Stack tokens={{ childrenGap: theme.spacing.s2 }}>
                  <Text styles={{ root: error && errorStyle }}>
                    {error?.message ||
                      "Enter the full identity object id for the user."}
                  </Text>
                  <Text variant="xSmall" styles={{ root: error && errorStyle }}>
                    eg: 0496e247-8d61-72b0-e7fa-861f5a85858b
                  </Text>
                </Stack>
              )}
              render={({
                field: { value, onChange },
                fieldState: { error }
              }) => {
                return (
                  <SelectedBox
                    isSelected={selectedField === "objectId"}
                    hasError={!!error}
                  >
                    <TextField
                      iconProps={{
                        iconName: "Contact"
                      }}
                      prefix="#"
                      label="Object Id"
                      autoComplete="off"
                      onClick={() => setSelectedField("objectId")}
                      value={value || ""}
                      onChange={onChange}
                    />
                  </SelectedBox>
                );
              }}
            />
          </Stack>

          <SelectedBox
            isSelected={
              selectedField === "givenNamePrefix" ||
              selectedField === "surnamePrefix"
            }
            styles={{ root: { flex: 1 } }}
            tokens={{ childrenGap: theme.spacing.m }}
          >
            <IdentityFilterField
              control={control}
              name="givenNamePrefix"
              tooltip={error => (
                <Stack tokens={{ childrenGap: theme.spacing.s2 }}>
                  <Text styles={{ root: error && errorStyle }}>
                    {error?.message ||
                      "Enter the start of or a full first name"}
                  </Text>
                  <Text variant="xSmall" styles={{ root: error && errorStyle }}>
                    eg: Pete
                  </Text>
                </Stack>
              )}
              render={({ field: { value, onChange } }) => {
                return (
                  <TextField
                    iconProps={{
                      iconName: "Contact"
                    }}
                    prefix='"'
                    label="Given Name"
                    autoComplete="off"
                    onClick={() => setSelectedField("givenNamePrefix")}
                    value={value || ""}
                    onChange={onChange}
                  />
                );
              }}
            />

            <IdentityFilterField
              control={control}
              name="surnamePrefix"
              tooltip={error => (
                <Stack tokens={{ childrenGap: theme.spacing.s2 }}>
                  <Text styles={{ root: error && errorStyle }}>
                    {error?.message || "Enter the start of or a full last name"}
                  </Text>
                  <Text variant="xSmall" styles={{ root: error && errorStyle }}>
                    eg: Smit
                  </Text>
                </Stack>
              )}
              render={({ field: { value, onChange } }) => {
                return (
                  <TextField
                    iconProps={{
                      iconName: "Contact"
                    }}
                    prefix='"'
                    label="Last Name"
                    autoComplete="off"
                    onClick={() => setSelectedField("surnamePrefix")}
                    value={value || ""}
                    onChange={onChange}
                    styles={{
                      root: {
                        paddingTop: 6 /* Custom alignment for grouped selected box */
                      }
                    }}
                  />
                );
              }}
            />
          </SelectedBox>

          <Stack
            styles={{ root: { flex: 1 } }}
            tokens={{ childrenGap: theme.spacing.s2 }}
          >
            <IdentityFilterField
              control={control}
              name="email"
              tooltip={error => (
                <Stack tokens={{ childrenGap: theme.spacing.s2 }}>
                  <Text styles={{ root: error && errorStyle }}>
                    {error?.message ||
                      "Enter the full email associated with the user."}
                  </Text>
                  <Text variant="xSmall" styles={{ root: error && errorStyle }}>
                    eg: user@domain.com
                  </Text>
                </Stack>
              )}
              render={({
                field: { value, onChange },
                fieldState: { error }
              }) => {
                return (
                  <SelectedBox
                    isSelected={selectedField === "email"}
                    hasError={!!error}
                  >
                    <TextField
                      iconProps={{
                        iconName: "Mail"
                      }}
                      prefix="@"
                      label="Email"
                      autoComplete="off"
                      onClick={() => setSelectedField("email")}
                      value={value || ""}
                      onChange={onChange}
                    />
                  </SelectedBox>
                );
              }}
            />

            <IdentityFilterField
              control={control}
              name="mobile"
              tooltip={error => (
                <Stack tokens={{ childrenGap: theme.spacing.s2 }}>
                  <Text styles={{ root: error && errorStyle }}>
                    {error?.message ||
                      "Enter the phone number in the international format. (without the +)"}
                  </Text>
                  <Text variant="xSmall" styles={{ root: error && errorStyle }}>
                    eg: 61412345678
                  </Text>
                </Stack>
              )}
              render={({
                field: { value, onChange },
                fieldState: { error }
              }) => {
                return (
                  <SelectedBox
                    isSelected={selectedField === "mobile"}
                    hasError={!!error}
                  >
                    <TextField
                      iconProps={{
                        iconName: "CellPhone"
                      }}
                      prefix="+"
                      label="Phone"
                      autoComplete="off"
                      onClick={() => setSelectedField("mobile")}
                      value={value || ""}
                      onChange={onChange}
                    />
                  </SelectedBox>
                );
              }}
            />
          </Stack>

          <Stack
            styles={{ root: { flex: 1 } }}
            tokens={{ childrenGap: theme.spacing.s2 }}
          >
            <IdentityFilterField
              control={control}
              name="userPrincipalNamePrefix"
              tooltip={error => (
                <Stack tokens={{ childrenGap: theme.spacing.s2 }}>
                  <Text styles={{ root: error && errorStyle }}>
                    {error?.message ||
                      "Enter the start of or the full principal name."}
                  </Text>
                  <Text variant="xSmall" styles={{ root: error && errorStyle }}>
                    eg:
                    f35590a0-5fe6-44a2-a445-c0493b0f6d34@besthealthb2cnonprod.onmicrosoft.com
                  </Text>
                </Stack>
              )}
              render={({
                field: { value, onChange },
                fieldState: { error }
              }) => {
                return (
                  <SelectedBox
                    isSelected={selectedField === "userPrincipalNamePrefix"}
                    hasError={!!error}
                  >
                    <TextField
                      iconProps={{
                        iconName: "Contact"
                      }}
                      prefix='"'
                      label="User Principal Name"
                      autoComplete="off"
                      onClick={() =>
                        setSelectedField("userPrincipalNamePrefix")
                      }
                      value={value || ""}
                      onChange={onChange}
                    />
                  </SelectedBox>
                );
              }}
            />
          </Stack>
        </Stack>
        <Stack
          horizontal
          verticalFill
          verticalAlign="center"
          tokens={{ childrenGap: theme.spacing.s1 }}
        >
          <PrimaryButton type="submit" disabled={isLoading}>
            Search
          </PrimaryButton>
          <DefaultButton type="reset" onClick={onClear} disabled={isLoading}>
            Clear Form
          </DefaultButton>
          {isLoading && (
            <Stack
              horizontal
              horizontalAlign="center"
              tokens={{ childrenGap: theme.spacing.s1 }}
            >
              <Spinner />
              <Text>Searching...</Text>
            </Stack>
          )}
        </Stack>
      </Stack>
    </form>
  );
};
