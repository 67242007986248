import { DataTable, DataTableColumn } from "@components/tables/DataTable";
import { EntitySyncState } from "@libs/api/gateways/tg/model/EntitySyncState";
import { useMemo } from "react";

import {
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  useTheme
} from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime, Duration } from "@bps/utils";
import { EntitySequence } from "@libs/api/gateways/best-health/practice/best-health-ops-gateway.dtos";

interface Props {
  bhbSyncState: EntitySequence[];
  prosEntitySyncState: EntitySyncState;
}

interface CombinedEntitySyncState {
  entityType: string;
  bhbSequenceNo?: number;
  bhbUpdatedDate?: DateTime;
  prosSequenceNo?: number;
  prosUpdatedDate?: DateTime;
  sequenceDifference?: number;
  deltaUpdatedMilliseconds?: number;
  rowCount?: number;
}

const TimeCell = ({ time }: { time?: DateTime }) => (
  <span>
    {time?.toFormat(DATE_FORMATS.LONG_DATE_TIME_FORMAT_MONTH_AHEAD) ?? "--"}
  </span>
);

const SyncDifferenceCell = ({ item }: { item: CombinedEntitySyncState }) => {
  if (item.sequenceDifference === undefined) return <>--</>;

  if (item.sequenceDifference === 0) {
    return (
      <TextBadge
        badgeSize={TextBadgeSize.small}
        badgeColor={TextBadgeColor.green}
      >
        In sync
      </TextBadge>
    );
  }

  return (
    <TextBadge
      badgeSize={TextBadgeSize.small}
      badgeColor={TextBadgeColor.red}
    >{`${item.sequenceDifference} behind`}</TextBadge>
  );
};

const DurationCell = ({
  duration,
  sequenceDifference
}: {
  duration?: number;
  sequenceDifference?: number;
}) => {
  const theme = useTheme();

  let color = "";
  let text: string | undefined;

  if (duration != null) {
    const durationLux = Duration.fromMillis(duration).shiftTo(
      "days",
      "hours",
      "minutes",
      "seconds",
      "milliseconds"
    );

    const durationObj = durationLux.toObject();

    if (durationObj.days) {
      text = durationLux.toFormat("d'd' h'h'");
    } else if (durationObj.hours) {
      text = durationLux.toFormat("h'h' m'm'");
    } else if (durationObj.minutes) {
      text = durationLux.toFormat("m'm' s's'");
    } else if (durationObj.seconds) {
      text = durationLux.toFormat("s's' S'ms'");
    } else {
      text = durationLux.toFormat("S'ms'");
    }

    if (durationObj.days && durationObj.days > 0) {
      // Only append "behind" if not in sync
      const suffix = sequenceDifference === 0 ? "" : " behind";
      text = `> ${durationObj.days} day${
        durationObj.days > 1 ? "s" : ""
      }${suffix}`;
      color = theme.semanticColors.errorText;
    } else {
      color = theme.semanticColors.successIcon;
    }
  }

  return <Text styles={{ root: { color } }}>{text ?? "--"}</Text>;
};

const bhbEntityTypeReplicationExclusions = [
  "Appointment",
  "DaysAway",
  "ExtraSession",
  "HealthCheck",
  "ReservedAppointment",
  "ReservedAppointmentReplication",
  "Session",
  "User",
  "UserPreference",
  "Practice"
];

export const EntitySyncStateTable = ({
  bhbSyncState,
  prosEntitySyncState
}: Props) => {
  // Get the intersection as well as the symmetrical difference between the arrays
  const combinedSyncState: CombinedEntitySyncState[] = useMemo(() => {
    const intersection = bhbSyncState.map(bhb => {
      const prosEntity = prosEntitySyncState.entitySequences.find(
        pes => pes.entityType === bhb.entityType
      );

      const combinedResult: CombinedEntitySyncState = {
        entityType: bhb.entityType,
        rowCount: bhb.rowCount,
        bhbSequenceNo: bhb.sequenceNo,
        bhbUpdatedDate: bhb.updatedDate,
        prosSequenceNo: prosEntity?.sequenceNo,
        prosUpdatedDate: prosEntity?.updatedDate,
        sequenceDifference:
          prosEntity?.sequenceNo && bhb.sequenceNo
            ? prosEntity.sequenceNo - bhb.sequenceNo
            : undefined,
        deltaUpdatedMilliseconds:
          prosEntity?.updatedDate && bhb.updatedDate
            ? Math.abs(
                bhb.updatedDate.diff(prosEntity.updatedDate).as("milliseconds")
              )
            : undefined
      };

      return combinedResult;
    });

    // The values that are in pros but not in bhb excluding ones we're not interested in
    const prosDifference = prosEntitySyncState.entitySequences
      .filter(
        pes =>
          !bhbEntityTypeReplicationExclusions.includes(pes.entityType) &&
          !bhbSyncState?.some(bhb => bhb.entityType === pes.entityType)
      )
      .map(pes => ({
        entityType: pes.entityType,
        prosSequenceNo: pes.sequenceNo,
        prosUpdatedDate: pes.updatedDate
      }));

    return [...intersection, ...prosDifference];
  }, [bhbSyncState, prosEntitySyncState]);

  const columns: DataTableColumn<CombinedEntitySyncState>[] = [
    {
      key: "entityType",
      name: "Entity Type",
      minWidth: 160,
      maxWidth: 350,
      sort: true,
      isSorted: true,
      filterable: true
    },
    {
      key: "bhbSequenceNo",
      name: "BHB Sequence Number",
      width: 180,
      maxWidth: 350,
      sort: true,
      onRender: (item: CombinedEntitySyncState) => item.bhbSequenceNo ?? "--"
    },
    {
      key: "bhbUpdatedDate",
      name: "BHB Updated Date",
      width: 180,
      maxWidth: 350,
      onRender: (item: CombinedEntitySyncState) => (
        <TimeCell time={item.bhbUpdatedDate} />
      )
    },
    {
      key: "prosSequenceNo",
      name: "PrOS Sequence Number",
      width: 180,
      maxWidth: 350,
      sort: true,
      onRender: (item: CombinedEntitySyncState) => item.prosSequenceNo ?? "--"
    },
    {
      key: "rowCount",
      name: "PrOS Row Count",
      width: 120,
      maxWidth: 350,
      sort: true,
      onRender: (item: CombinedEntitySyncState) => item.rowCount ?? "--"
    },
    {
      key: "prosUpdatedDate",
      name: "PrOS Updated Date",
      width: 180,
      maxWidth: 350,
      onRender: (item: CombinedEntitySyncState) => (
        <TimeCell time={item.prosUpdatedDate} />
      )
    },
    {
      key: "sequenceDifference",
      name: "Sync State",
      width: 100,
      maxWidth: 350,
      onRender: (item: CombinedEntitySyncState) => (
        <SyncDifferenceCell item={item} />
      )
    },
    {
      key: "deltaUpdatedMilliseconds",
      name: "Delay",
      width: 100,
      maxWidth: 350,
      onRender: (item: CombinedEntitySyncState) => (
        <DurationCell
          duration={item.deltaUpdatedMilliseconds}
          sequenceDifference={item.sequenceDifference}
        />
      )
    }
  ];

  const getKey = (entitySyncState: CombinedEntitySyncState) =>
    entitySyncState.entityType;

  return (
    <DataTable
      items={combinedSyncState}
      columns={columns}
      getKey={getKey}
      onShouldVirtualize={() => false}
    />
  );
};
