import { Controller, FormProvider, useForm } from "react-hook-form";

import {
  DefaultButton,
  Dialog,
  DialogFooter,
  getTheme,
  IDialogProps,
  IDropdownOption,
  PrimaryButton,
  Stack,
  Text
} from "@bps/fluent-ui";
import { FormAdUserSearch } from "@components/form/fields/FormAdUserSearch";
import { FormDropdown } from "@components/form/fields/FormDropdown";
import { FormSwitch } from "@components/form/fields/FormSwitch";
import { validationResolver } from "@components/form/validation/validation-resolver";
import { Validator } from "@components/form/validation/Validator";
import { EnvUser } from "@libs/api/gateways/env/env-gateway.dtos";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { Permissions } from "@libs/permissions/permissions.enum";

interface ManageUser {
  id?: string;
  roles?: string[];
  isInactive?: boolean;
}

interface Props extends IDialogProps {
  user: EnvUser | undefined;
  onSave: (user: EnvUser, isCreate: boolean) => void;
}

const validator = new Validator<ManageUser>(validator => ({
  id: validator.string().required({ message: "User is required" }),
  roles: validator.array().required({ message: "Roles is required" })
}));

export const UserModal = ({
  user: initialUser,
  onSave,
  onDismiss,
  ...modalProps
}: Props) => {
  const theme = getTheme();

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.EnvSecurityWrite
  );

  const form = useForm<ManageUser>({
    mode: "onChange",
    defaultValues: {
      id: initialUser?.id,
      roles: initialUser?.roles,
      isInactive: initialUser?.isInactive
    },
    resolver: values =>
      validationResolver<ManageUser>(validator.validateWithParse, values)
  });

  const handleSubmit = async (user: ManageUser) => {
    onSave(
      {
        ...initialUser,
        id: user.id,
        isInactive: !!user.isInactive,
        roles: user.roles
      },
      !initialUser?.id
    );
    onDismiss?.();
  };

  const handleCancel = () => {
    form.reset();
    onDismiss?.();
  };

  // TODO: Get this from the roles loaded in the Cache
  const roleOptions: IDropdownOption[] = [
    { key: "ADMIN", text: "Administrator" },
    { key: "DEVELOPER", text: "Developer" },
    {
      key: "DEVELOPERSUPPORT",
      text: "Developer Support (add on beyond developer)"
    },
    { key: "DEVOPS", text: "DevOps" },
    { key: "PRODUCT", text: "Product" },
    { key: "SALES", text: "Sales" },
    { key: "SUPPORT", text: "Support" },
    { key: "VIEWER", text: "Basic view mode" },
    { key: "ENV.USERADMIN", text: "Environment User Administrator" }
  ];

  return (
    <FormProvider<ManageUser> {...form}>
      <Dialog
        styles={{
          main: {
            borderTop: `4px solid ${theme.palette.themePrimary}`
          }
        }}
        dialogContentProps={{
          title: "User Details",
          showCloseButton: true,
          onDismiss
        }}
        {...modalProps}
      >
        <Stack
          as="form"
          onSubmit={form.handleSubmit(handleSubmit)}
          verticalFill
          tokens={{ childrenGap: theme.spacing.l2 }}
        >
          <Stack verticalFill tokens={{ childrenGap: theme.spacing.m }}>
            <Controller
              control={form.control}
              name="id"
              render={({ field: { ref, ...fieldProps } }) => (
                <FormAdUserSearch
                  label="User"
                  data-test="user-id"
                  placeholder="User Search"
                  disabled={!!initialUser?.id || !hasWritePermission}
                  onRenderField={
                    initialUser
                      ? () => <Text>{initialUser.name}</Text>
                      : undefined
                  }
                  {...fieldProps}
                />
              )}
            />

            <FormDropdown
              name="roles"
              placeholder="Select Option"
              label="Roles"
              multiSelect
              disabled={!hasWritePermission}
              options={roleOptions}
              data-test="user-roles"
            />

            {initialUser?.id && (
              <FormSwitch
                name="isInactive"
                label="Inactive"
                disabled={!hasWritePermission}
                data-test="switch-inactive"
              />
            )}
          </Stack>

          <DialogFooter>
            <DefaultButton
              text="Cancel"
              onClick={handleCancel}
              data-test="cancel-button"
            />
            <PrimaryButton
              text="Save"
              type="submit"
              disabled={!hasWritePermission}
              data-test="save-button"
            />
          </DialogFooter>
        </Stack>
      </Dialog>
    </FormProvider>
  );
};
