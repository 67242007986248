import React, { FunctionComponent, useMemo } from "react";

import { Stack } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { FormDatePicker } from "@components/form/fields/FormDatePicker";
import { pinLengthSchema } from "@components/form/fields/FormPin.validator";
import { FormPinField } from "@components/form/fields/FormPinField";
import { FormTextField } from "@components/form/fields/FormTextField";
import { Form } from "@components/form/Form";
import { Validator } from "@components/form/validation/Validator";
import {
  useAddRedirectLink,
  useUpdatedRedirectLink
} from "@libs/api/gateways/plt-redirect/plt-redirect-gateway.hooks";

import {
  AddRedirectLinkFormProps,
  RedirectLinkFormValues
} from "./AddRedirectLinkDialog.types";
import { ValidationMessages } from "@libs/validation/validation-messages.enum";
import { FormDropdown } from "@components/form/fields/FormDropdown";

enum Protocols {
  Http = "http",
  Https = "https"
}
const SPLIT_URL = "://";

const validator = new Validator<RedirectLinkFormValues>();
export const AddRedirectLinkForm: FunctionComponent<AddRedirectLinkFormProps> = ({
  onDismiss,
  redirectLink
}) => {
  const isEdit = !!redirectLink;

  const {
    error: addError,
    mutateAsync: addRedirectLink
  } = useAddRedirectLink();

  const {
    error: editError,
    mutateAsync: editRedirectLink
  } = useUpdatedRedirectLink();

  const defaultValues: RedirectLinkFormValues = useMemo(() => {
    if (isEdit && redirectLink) {
      const splitRedirectUrl = (redirectLink.redirectUrl ?? "")?.split(
        SPLIT_URL
      );

      const hasNoProtocol = splitRedirectUrl?.length === 1;
      return {
        redirectUrl: hasNoProtocol ? splitRedirectUrl[0] : splitRedirectUrl[1],
        redirectUrlScheme: !hasNoProtocol ? splitRedirectUrl[0] : undefined,
        notAfterUtc: redirectLink.notAfterUtc
          ? DateTime.fromISO(redirectLink.notAfterUtc).toJSDate()
          : undefined,
        notBeforeUtc: redirectLink.notBeforeUtc
          ? DateTime.fromISO(redirectLink.notBeforeUtc).toJSDate()
          : undefined
      };
    }
    return {
      redirectUrl: "",
      redirectUrlScheme: Protocols.Https,
      notAfterUtc: new Date(),
      notBeforeUtc: undefined,
      prefix: ""
    };
  }, [isEdit, redirectLink]);

  const onSubmit = async (values: RedirectLinkFormValues) => {
    const { notAfterUtc, notBeforeUtc, ...restValues } = values;
    const convertedDates = {
      notBeforeUtc: notBeforeUtc ? notBeforeUtc.toISOString() : undefined,
      notAfterUtc: notAfterUtc ? notAfterUtc.toISOString() : undefined
    };

    try {
      if (redirectLink) {
        const payload = { ...redirectLink, ...restValues, ...convertedDates };
        await editRedirectLink(payload);
      } else {
        if (values.prefix) {
          const payload = {
            ...restValues,
            ...convertedDates,
            prefix: values.prefix
          };
          await addRedirectLink(payload);
        } else {
          throw Error("Prefix is required.");
        }
      }
      onDismiss();
    } catch (e) {
      return e;
    }
  };

  return (
    <Form<RedirectLinkFormValues>
      onSubmit={onSubmit}
      validate={values => {
        return validator.validateWithParse(values, {
          redirectUrlScheme: validator
            .string()
            .required({ message: ValidationMessages.required }),
          redirectUrl: [
            validator
              .string()
              .required({ message: ValidationMessages.required }),
            validator.string().url({
              protocolValidationLevel: "forbidden",
              subdomainValidationLevel: "forbidden",
              message:
                "Invalid URL format: Please ensure your URL does not start with 'http://' or 'https://'. Only plain URLs are allowed."
            })
          ],
          prefix: validator.custom().predicate({
            when: () => !isEdit,
            then: validator.string().required()
          }),
          notAfterUtc: validator.date().required(),
          pin: validator
            .custom()
            .predicate({ when: () => !isEdit, then: pinLengthSchema })
        });
      }}
      defaultValues={defaultValues}
      error={addError ?? editError}
    >
      <Stack horizontal tokens={{ childrenGap: 8 }} horizontalAlign="center">
        <FormDropdown
          styles={{ root: { width: 112 } }}
          options={[
            { key: Protocols.Https, text: Protocols.Https },
            { key: Protocols.Http, text: Protocols.Http }
          ]}
          name="redirectUrlScheme"
          label="Shema"
          required
        />
        <FormTextField name="redirectUrl" label="Redirect url" required />
      </Stack>

      {!isEdit && <FormTextField name="prefix" label="Prefix" required />}
      <Stack horizontal tokens={{ childrenGap: 8 }}>
        <FormDatePicker name="notBeforeUtc" label="Date before" />
        <FormDatePicker name="notAfterUtc" label="Date after" required />
      </Stack>
      {!isEdit && <FormPinField name="pin" />}
    </Form>
  );
};
