import { FunctionComponent } from "react";
import {
  CreateSettingDialogProps,
  CreateSettingFormValues
} from "./CreateSettingDialog.types";
import { Dialog, Heading, Stack } from "@bps/fluent-ui";
import { Form } from "@components/form/Form";
import { FormTextField } from "@components/form/fields/FormTextField";
import {
  useCreateComponentSetting,
  useUpdateComponentSetting
} from "@libs/api/gateways/plt/plt-gateway.hooks";
import { Validator } from "@components/form/validation/Validator";

const createSettingsValidator = new Validator<CreateSettingFormValues>(
  validator => ({
    key: [validator.string().required(), validator.string().maxLength(100)],
    desiredValue: validator.string().required()
  })
);

export const CreateSettingDialog: FunctionComponent<CreateSettingDialogProps> = ({
  onDismiss,
  code,
  scopeId,
  tenantId,
  initialValues
}) => {
  const isEdit = !!initialValues.key;
  const createSetting = useCreateComponentSetting({ code, scopeId, tenantId });
  const updateSetting = useUpdateComponentSetting({
    code,
    scopeId,
    tenantId
  });

  return (
    <Dialog
      hidden={false}
      onDismiss={onDismiss}
      dialogContentProps={{
        title: `${isEdit ? "Update" : "Create"} setting`,
        showCloseButton: true
      }}
    >
      <Form<CreateSettingFormValues>
        defaultValues={initialValues}
        onSubmit={async values => {
          const payload = { key: values.key!, value: values.desiredValue! };
          if (!isEdit) {
            await createSetting.mutateAsync({
              settings: [payload]
            });
          } else {
            await updateSetting.mutateAsync(payload);
          }
          onDismiss();
        }}
        error={createSetting.error ?? updateSetting.error}
        onCancel={onDismiss}
        showCancelButton
        validate={createSettingsValidator.validateWithParse}
      >
        {!isEdit ? (
          <FormTextField name="key" label="Key" required disabled={isEdit} />
        ) : (
          <Stack tokens={{ childrenGap: 16 }}>
            <Stack.Item>
              <Heading labelPaddings>Key</Heading>
              {initialValues.key}
            </Stack.Item>
            <Stack.Item>
              <Heading labelPaddings>Value</Heading>
              {initialValues.value ?? "-"}
            </Stack.Item>
          </Stack>
        )}

        <FormTextField name="desiredValue" label="Desired value" required />
      </Form>
    </Dialog>
  );
};
