import { FunctionComponent } from "react";
import { useParams } from "react-router-dom";

import { FilterBar, Stack, useTheme } from "@bps/fluent-ui";
import { SectionTitle } from "@components/SectionTitle";
import { withPermissions } from "@components/withPermissions";
import { ServiceAgreementsArgs } from "@libs/api/gateways/cam/cam-gateway.dtos";
import { Permissions } from "@libs/permissions/permissions.enum";

import { ServiceAgreementsList } from "./components/ServiceAgreementsList";

const ServiceAgreementsScreenBase: FunctionComponent = () => {
  const theme = useTheme();

  const params = useParams();
  const { tenantId } = params;

  return (
    <Stack
      verticalFill
      styles={{ root: { padding: theme.spacing.s1 } }}
      tokens={{ childrenGap: theme.spacing.l2 }}
    >
      <Stack horizontal horizontalAlign="space-between">
        <SectionTitle>Service Agreements</SectionTitle>
      </Stack>
      <Stack
        tokens={{ childrenGap: 8 }}
        styles={{ root: { position: "relative" } }}
        grow
      >
        <FilterBar<ServiceAgreementsArgs>
          items={[
            {
              name: "tenantId",
              type: "searchBox",
              stickItem: true,
              props: {
                placeholder: "Tenant Id",
                removeSpecialCharacters: false
              }
            },
            {
              name: "productFamily",
              type: "searchBox",
              props: {
                placeholder: "Product Family",
                removeSpecialCharacters: false
              }
            }
          ]}
        >
          {state => {
            return (
              <ServiceAgreementsList filter={{ ...state.values, tenantId }} />
            );
          }}
        </FilterBar>
      </Stack>
    </Stack>
  );
};

export const ServiceAgreementsScreen = withPermissions(
  ServiceAgreementsScreenBase,
  [Permissions.PltSalesRead, Permissions.PltSalesWrite],
  "or"
);
