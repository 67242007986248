import { PreviewSubscriptionPayload } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { flatten } from "@bps/fluent-ui";
import { SubscriptionFormValues } from "../subscription-form/SubscriptionForm.types";

export const getPreviewSubscriptionPayload = (
  values: SubscriptionFormValues,
  customerTenantId: string
): PreviewSubscriptionPayload => {
  const promotionCodeList = [
    ...(values.promotionCodeList ?? []),
    ...(values.additionalPromotionCodeList ?? [])
  ];
  return {
    customerTenantId,
    promotionCodeList,
    items: flatten(values.subscriptionProducts.map(sp => sp.childTenants)).map(
      item => ({
        quantity: item.scheduledQuantity ? Number(item.scheduledQuantity) : 0,
        childTenantId: item.childTenantId,
        salesProductId: item.salesProductId
      })
    )
  };
};
