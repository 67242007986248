import { IconButton, Stack, Text, useTheme } from "@bps/fluent-ui";
import { DATE_FORMATS } from "@bps/utils";
import { DataTable, DataTableColumn } from "@components/tables/DataTable";
import { BpMobileNotification } from "@libs/api/gateways/bp-mobile/bp-mobile.dtos";
import { useDeleteNotification } from "@libs/api/gateways/bp-mobile/bp-mobile.hooks";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { Permissions } from "@libs/permissions/permissions.enum";

interface NotificationProps {
  notifications: BpMobileNotification[];
  selectNotification: (notification: BpMobileNotification) => void;
}

export const NotificationsTable = ({
  notifications,
  selectNotification
}: NotificationProps) => {
  const theme = useTheme();

  const { mutateAsync: deleteNotification } = useDeleteNotification();

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.BhMobileNotificationsWrite
  );

  const deleteBpMobileNotification = async (
    notification: BpMobileNotification
  ) => {
    if (notification.partitionKey && notification.rowKey) {
      await deleteNotification({
        partitionKey: notification.partitionKey,
        rowKey: notification.rowKey
      });
    }
  };

  const columns: DataTableColumn<BpMobileNotification>[] = [
    {
      name: "",
      key: "edit",
      minWidth: 32,
      maxWidth: 32,
      onRender: (notification: BpMobileNotification) => (
        <IconButton
          iconProps={{ iconName: "Edit" }}
          styles={{ root: { justifySelf: "end", alignSelf: "start" } }}
          onClick={() => {
            selectNotification(notification);
          }}
          disabled={!hasWritePermission}
        />
      )
    },
    {
      key: "startTime",
      name: "Start Time",
      maxWidth: 200,
      isSorted: true,
      sort: true,
      onRender: (notification: BpMobileNotification) => (
        <Text variant="small">
          {notification.startTime
            ?.setZone("utc")
            .toFormat(DATE_FORMATS.LONG_DATE_TIME_FORMAT)}
        </Text>
      )
    },
    {
      key: "endTime",
      name: "End Time",
      maxWidth: 200,
      isSorted: true,
      sort: true,
      onRender: (notification: BpMobileNotification) => (
        <Text variant="small">
          {notification.endTime
            ?.setZone("utc")
            .toFormat(DATE_FORMATS.LONG_DATE_TIME_FORMAT)}
        </Text>
      )
    },
    {
      key: "outageMessage",
      name: "Outage Message",
      minWidth: 600,
      onRender: (notification: BpMobileNotification) =>
        notification.outageMessage
    },
    {
      name: "",
      key: "delete",
      minWidth: 32,
      maxWidth: 32,
      onRender: (notification: BpMobileNotification) => (
        <IconButton
          iconProps={{ iconName: "Delete" }}
          styles={{ root: { justifySelf: "end", alignSelf: "start" } }}
          onClick={() => {
            deleteBpMobileNotification(notification);
          }}
          disabled={!hasWritePermission}
        />
      )
    }
  ];

  return (
    <>
      <Stack
        verticalFill
        tokens={{
          childrenGap: theme.spacing.s2
        }}
        styles={{
          root: {
            flex: "1 1 0",
            overflowY: "auto",
            backgroundColor: theme.semanticColors.bodyBackground,
            padding: theme.spacing.s1
          }
        }}
      >
        <DataTable
          items={notifications}
          columns={columns}
          onShouldVirtualize={() => true}
        />
      </Stack>
    </>
  );
};
