import { withPermissions } from "@components/withPermissions";
import React, { FunctionComponent, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Stack, useTheme } from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";
import {
  useChildTenants,
  useCustomerProducts
} from "@libs/api/gateways/plt/plt-gateway.hooks";
import { Permissions } from "@libs/permissions/permissions.enum";

import { SubscriptionForm } from "./components/subscription-form/SubscriptionForm";

interface SubscriptionEditScreenProps {
  tenant: Tenant;
}

const SubscriptionEditScreenBase: FunctionComponent<SubscriptionEditScreenProps> = ({
  tenant
}) => {
  const { id } = useParams<{ id: string }>();
  const theme = useTheme();
  const navigate = useNavigate();
  const customerProductsQuery = useCustomerProducts(tenant.id);
  const childTenantsQuery = useChildTenants(tenant.id);

  useEffect(() => {
    // navigate to a screen back if not subscription id in router parameters
    if (!id) {
      navigate(-1);
    }
  }, [id, navigate]);

  return (
    <Stack
      verticalFill
      styles={{ root: { padding: theme.spacing.s1 } }}
      tokens={{ childrenGap: theme.spacing.l2 }}
      verticalAlign="space-between"
    >
      <QueryStateIndicator
        data={{
          customerProducts: customerProductsQuery.data,
          childTenants: childTenantsQuery.data
        }}
        isLoading={
          customerProductsQuery.isLoading || childTenantsQuery.isLoading
        }
        error={customerProductsQuery.error ?? childTenantsQuery.error}
        allowNullOrUndefined
      >
        {({ customerProducts, childTenants }) => (
          <SubscriptionForm
            customerProducts={customerProducts}
            childTenants={childTenants ?? []}
            tenant={tenant}
          />
        )}
      </QueryStateIndicator>
    </Stack>
  );
};

export const SubscriptionEditScreen = withPermissions(
  SubscriptionEditScreenBase,
  [Permissions.PltSalesRead, Permissions.PltSalesWrite],
  "or"
);
