import { Stack, Text } from "@bps/fluent-ui";

import { FunctionComponent } from "react";
import {
  Country,
  SubscriptionPreviewDto
} from "@libs/api/gateways/plt/plt-gateway.dtos";
import { currencyFormat } from "../../../invoices/utils";

interface PaymentDetailsTotalsProps {
  previewData: SubscriptionPreviewDto;
  country: Country;
}

export const PreviewSubscriptionTotals: FunctionComponent<PaymentDetailsTotalsProps> = ({
  previewData,
  country
}) => {
  const hasDiscount = !!previewData?.discountAmount;

  return (
    <Stack className="cam-PaymentDetailsTotals" tokens={{ childrenGap: 8 }}>
      <Stack horizontal horizontalAlign="space-between">
        <Text bold>Subtotal</Text>
        <Text bold>
          {currencyFormat(
            (previewData?.subtotalExcludingTax ?? 0) / 100,
            country
          )}
        </Text>
      </Stack>

      {hasDiscount && (
        <Stack horizontal horizontalAlign="space-between">
          <Text>{previewData.discountDescription}</Text>
          <Text>
            -{" "}
            {currencyFormat((previewData?.discountAmount ?? 0) / 100, country)}
          </Text>
        </Stack>
      )}

      <Stack horizontal horizontalAlign="space-between">
        <Text bold>Total excluding tax</Text>
        <Text bold>
          {currencyFormat(
            (previewData?.amountExcludingTax ?? 0) / 100,
            country
          )}
        </Text>
      </Stack>

      <Stack horizontal horizontalAlign="space-between">
        <Text>
          GST ({previewData.taxPercentage}% on{" "}
          {currencyFormat(
            (previewData?.amountExcludingTax ?? 0) / 100,
            country
          )}
          )
        </Text>
        <Text>{currencyFormat((previewData?.tax ?? 0) / 100, country)}</Text>
      </Stack>
    </Stack>
  );
};
