import { useMemo } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import {
  DefaultButton,
  DetailsRow,
  IColumn,
  IconButton,
  IDropdownOption,
  mergeStyleSets,
  useTheme
} from "@bps/fluent-ui";
import { dangerButtonStyles } from "@components/buttons/DangerButton";
import { FormDropdown } from "@components/form/fields/FormDropdown";
import { FormTextField } from "@components/form/fields/FormTextField";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { ShimmeredDetailsList } from "@components/tables/ShimmeredDetailsList";
import { useSelectorDataTypeRefData } from "@libs/api/gateways/field/field-ops-gateway.hooks";

import { SelectorFormRule, SelectorFormValues } from "./formUtils";

// Operator Options
export const OperatorOptions = [{ key: "=", text: "=" }];

export const SelectorRuleTable = () => {
  const theme = useTheme();
  const { control } = useFormContext<SelectorFormValues>();

  const refDataQuery = useSelectorDataTypeRefData();

  const {
    fields: currentValues,
    append: appendRules,
    remove: removeRules
  } = useFieldArray({
    control,
    name: "rules"
  });

  const onAdd = async () => {
    appendRules({
      attributeType: "",
      value: "",
      comparisonOperator: OperatorOptions[0].key, // default operator is "="
      id: crypto.randomUUID(),
      valueDataType: "STRING"
    });
  };

  const columns: IColumn[] = useMemo(() => {
    const onDelete = async (rule: SelectorFormRule) => {
      removeRules(currentValues.findIndex(val => val.id === rule.id));
    };

    const RuleValueColumn = (currentRule: SelectorFormRule) => {
      const ruleValueCol = `rules[${currentValues.findIndex(
        value => value.id === currentRule.id
      )}].value`;
      return <FormTextField required name={ruleValueCol} />;
    };

    const RuleValueDataTypeColumn = (currentRule: SelectorFormRule) => {
      const ruleValueCol = `rules[${currentValues.findIndex(
        value => value.id === currentRule.id
      )}].valueDataType`;

      return (
        <QueryStateIndicator {...refDataQuery}>
          {data => {
            const options = data?.map(ref => ({
              key: ref.code,
              text: ref.code,
              title: ref.text
            })) as IDropdownOption[];

            return (
              <FormDropdown required name={ruleValueCol} options={options} />
            );
          }}
        </QueryStateIndicator>
      );
    };

    const RuleNameColumn = (currentRule: SelectorFormRule) => {
      const ruleValueCol = `rules[${currentValues.findIndex(
        value => value.id === currentRule.id
      )}].attributeType`;
      return <FormTextField required name={ruleValueCol} />;
    };

    const RuleOperator = (currentRule: SelectorFormRule) => {
      const ruleValueCol = `rules[${currentValues.findIndex(
        value => value.id === currentRule.id
      )}].comparisonOperator`;
      return (
        <FormDropdown name={ruleValueCol} required options={OperatorOptions} />
      );
    };

    return [
      {
        key: "name",
        name: "Attribute name",
        minWidth: 150,
        maxWidth: 300,
        isResizable: true,
        onRender: (rule: SelectorFormRule) => <RuleNameColumn {...rule} />
      },
      {
        key: "operator",
        name: "Operator",
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        onRender: (rule: SelectorFormRule) => <RuleOperator {...rule} />
      },
      {
        key: "value",
        name: "Attribute Value",
        minWidth: 150,
        isResizable: true,
        onRender: (rule: SelectorFormRule) => <RuleValueColumn {...rule} />
      },
      {
        key: "dataType",
        name: "Attribute DataType",
        minWidth: 150,
        isResizable: true,
        onRender: (rule: SelectorFormRule) => (
          <RuleValueDataTypeColumn {...rule} />
        )
      },
      {
        key: "delete",
        name: "",
        minWidth: 30,
        maxWidth: 30,
        onRender: (selector: SelectorFormRule) => (
          <IconButton
            onClick={() => {
              onDelete(selector);
            }}
            styles={mergeStyleSets(dangerButtonStyles(theme), {
              root: {
                background: "transparent"
              }
            })}
            iconProps={{ iconName: "Cancel" }}
          />
        ),
        sort: false
      }
    ];
  }, [currentValues, refDataQuery, removeRules, theme]);

  return (
    <>
      <ShimmeredDetailsList
        shimmerLines={5}
        columns={columns}
        onShouldVirtualize={() => true}
        onRenderRow={row => (row ? <DetailsRow {...row} /> : null)}
        items={currentValues}
      />
      <DefaultButton
        iconProps={{ iconName: "Add" }}
        styles={{ root: { width: "max-content" } }}
        onClick={onAdd}
      >
        Add rule
      </DefaultButton>
    </>
  );
};
