import { withPermissions } from "@components/withPermissions";
import { SectionTitle } from "@components/SectionTitle";
import { Permissions } from "@libs/permissions/permissions.enum";

import { useState } from "react";

import { Stack, Text, useTheme } from "@bps/fluent-ui";

import { EmailAuditingFilter } from "./EmailAuditingFilter";
import { EmailAuditingTable } from "./EmailAuditingTable";
import { OutboundEmailAuditArgs } from "@libs/api/gateways/plt-comms/plt-comms-gateway.dtos";
import { useOutboundEmailAuditQuery } from "@libs/api/gateways/plt-comms/plt-comms-gateway.hooks";

const EmailsAuditingBase: React.FC = () => {
  const [filtersArgs, setFilterArgs] = useState<
    OutboundEmailAuditArgs | undefined
  >(undefined);

  const { data: emails, isLoading } = useOutboundEmailAuditQuery(filtersArgs);
  const theme = useTheme();
  const moreRecordsThenSearch = emails?.total! / 150 > 1;

  return (
    <>
      <SectionTitle>Email Auditing</SectionTitle>
      <Text>
        To seach for emails, please provide filters for both email or subject
        and date range.
      </Text>
      <Stack grow styles={{ root: { position: "relative" } }}>
        <EmailAuditingFilter setFilterArgs={setFilterArgs} />
        {!isLoading && (
          <Stack styles={{ root: { alignItems: "center", display: "flex" } }}>
            {moreRecordsThenSearch && (
              <Text variant="xSmall">
                Only displaying 150 records at a time. Please refine search
              </Text>
            )}
            <Text
              styles={{
                root: {
                  background: theme.semanticColors.listItemBackgroundChecked,
                  width: "fit-content",
                  padding: 2
                }
              }}
            >
              {emails?.total ?? "no"} records found
            </Text>
          </Stack>
        )}
        <EmailAuditingTable
          items={emails?.results ?? []}
          selectionPreservedOnEmptyClick
          setKey="deploymentRing-Table"
          enableShimmer={isLoading}
          shimmerLines={10}
        />
      </Stack>
    </>
  );
};

export const EmailsAuditing = withPermissions(
  EmailsAuditingBase,
  [Permissions.PltCatalogOpsRead, Permissions.PltCatalogOpsWrite],
  "or"
);
