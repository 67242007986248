import { CopyableGuid } from "@components/CopyableText";
import { DataTable, DataTableColumn } from "@components/tables/DataTable";
import { SectionTitle } from "@components/SectionTitle";
import { withPermissions } from "@components/withPermissions";
import React, { FunctionComponent, useState } from "react";

import {
  IconButton,
  NoDataTile,
  PrimaryButton,
  ScrollablePane,
  Stack,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  useTheme
} from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime } from "@bps/utils";
import { ApplicationBadge } from "@components/ApplicationBadge";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { SalesProductDto } from "@libs/api/gateways/plt/plt-gateway.dtos";
import {
  useResyncStripeProducts,
  useSalesProducts
} from "@libs/api/gateways/plt/plt-gateway.hooks";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";

import { ProductFormDialog } from "./product-form-dialog/ProductFormDialog";
import { ProductId } from "./product-form-dialog/ProductFormDialog.types";
import { ProductsFilterBar } from "./ProductsFilterBar";
import { getSortedFilteredProducts } from "./utils";

const ProductsScreenBase: FunctionComponent = () => {
  const theme = useTheme();
  const [productId, setProductId] = useState<ProductId>(undefined);

  const { isLoading, mutate } = useResyncStripeProducts();

  const handleResyncClick = () => {
    mutate();
  };

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltCatalogOpsWrite
  );

  const columns: DataTableColumn<SalesProductDto>[] = [
    {
      name: "",
      key: "edit",
      minWidth: 30,
      maxWidth: 30,
      onRender: (item: SalesProductDto) => (
        <IconButton
          disabled={!hasWritePermission}
          title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
          iconProps={{ iconName: "Edit" }}
          onClick={() => setProductId(item.id)}
        />
      )
    },
    {
      name: "ID",
      key: "id",
      minWidth: 100,
      maxWidth: 100,
      onRender: (item: SalesProductDto) => (
        <CopyableGuid value={item.id} short />
      )
    },
    {
      name: "Source product id",
      key: "source_id",
      minWidth: 120,
      maxWidth: 120,
      onRender: (item: SalesProductDto) =>
        item.sourceProductId && (
          <CopyableGuid value={item.sourceProductId} short />
        )
    },
    {
      name: "Description",
      key: "description",
      minWidth: 200,
      maxWidth: 600,
      onRender: (item: SalesProductDto) => (
        <Text styles={{ root: { whiteSpace: "normal" } }}>
          {item.description}
        </Text>
      )
    },
    {
      name: "Status",
      key: "status",
      minWidth: 55,
      maxWidth: 55,
      onRender: (item: SalesProductDto) => (
        <TextBadge
          badgeSize={TextBadgeSize.small}
          badgeColor={
            !item.isInactive ? TextBadgeColor.green : TextBadgeColor.red
          }
        >
          {!item.isInactive ? "Active" : "Inactive"}
        </TextBadge>
      )
    },
    {
      name: "Application",
      key: "application",
      minWidth: 80,
      maxWidth: 80,
      onRender: (item: SalesProductDto) => (
        <ApplicationBadge code={item.applicationCode} />
      )
    },
    {
      name: "Licence code",
      key: "licence_code_type",
      minWidth: 100,
      maxWidth: 100,
      onRender: (item: SalesProductDto) => item.licenceType
    },
    {
      name: "Family",
      key: "family",
      minWidth: 100,
      maxWidth: 100,
      onRender: (item: SalesProductDto) => item.productFamily
    },
    {
      name: "Code",
      key: "code",
      minWidth: 100,
      maxWidth: 100,
      onRender: (item: SalesProductDto) => item.productCode
    },
    {
      name: "Country",
      key: "country",
      minWidth: 55,
      maxWidth: 55,
      onRender: (item: SalesProductDto) => item.country ?? "-"
    },
    {
      name: "Interval",
      key: "interval",
      minWidth: 55,
      maxWidth: 55,
      onRender: (item: SalesProductDto) => item.interval
    },
    {
      name: "Created/updated",
      key: "created",
      minWidth: 120,
      maxWidth: 120,
      onRender: (item: SalesProductDto) =>
        item.changeLog?.updatedDate
          ? DateTime.fromISO(item.changeLog?.updatedDate).toFormat(
              DATE_FORMATS.LONG_DATE_TIME_FORMAT
            )
          : undefined
    }
  ];

  const productsQuery = useSalesProducts({}, { refetchOnMount: "always" });
  return (
    <Stack
      verticalFill
      styles={{ root: { padding: theme.spacing.s1 } }}
      tokens={{ childrenGap: theme.spacing.l2 }}
    >
      <Stack horizontalAlign="space-between" horizontal>
        <SectionTitle>Products</SectionTitle>
        <ProductFormDialog
          productId={productId}
          closeDialog={() => setProductId(undefined)}
        />
        <Stack
          horizontalAlign="space-between"
          horizontal
          tokens={{ childrenGap: 8 }}
        >
          <PrimaryButton
            styles={{ root: { width: 200 } }}
            onClick={handleResyncClick}
            title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
            disabled={isLoading || !hasWritePermission}
          >
            {isLoading ? "Resyncing..." : "Resync Stripe products"}
          </PrimaryButton>
          <PrimaryButton
            disabled={!hasWritePermission}
            title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
            onClick={() => {
              setProductId("new");
            }}
          >
            Create product
          </PrimaryButton>
        </Stack>
      </Stack>
      <QueryStateIndicator {...productsQuery}>
        {data =>
          !!data.length ? (
            <Stack grow>
              <ProductsFilterBar products={data}>
                {({ values: filter }) => (
                  <Stack
                    styles={{ root: { position: "relative", height: "100%" } }}
                    grow
                  >
                    <ScrollablePane>
                      <DataTable
                        items={getSortedFilteredProducts(data, filter)}
                        columns={columns}
                        stickyHeader
                      />
                    </ScrollablePane>
                  </Stack>
                )}
              </ProductsFilterBar>
            </Stack>
          ) : (
            <NoDataTile
              showBoxShadow={false}
              textProps={{ text: "No products found" }}
              linkProps={{ hidden: true }}
            />
          )
        }
      </QueryStateIndicator>
    </Stack>
  );
};

export const ProductsScreen = withPermissions(
  ProductsScreenBase,
  [Permissions.PltCatalogOpsRead, Permissions.PltCatalogOpsWrite],
  "or"
);
