import { ValidationSchema } from "@bps/utils";
import { LicenceFormValues } from "modules/tenants/sections/plt/licencing/licence-form-dialog/LicenceFormDialog.types";
import { Validator } from "@components/form/validation/Validator";

export const licenceFormValidator = new Validator<LicenceFormValues>();

export const licenceFormSchema: ValidationSchema<LicenceFormValues> = {
  licenceTypeCode: licenceFormValidator
    .string()
    .required({ message: "Licence type code is required" }),
  productId: licenceFormValidator
    .string()
    .required({ message: "Product is required" }),
  expiryDate: [
    licenceFormValidator.date().todayOrGreater(),
    licenceFormValidator.date().required({ message: "Expiry date is required" })
  ]
};
