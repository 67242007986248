export enum Options {
  on = 0,
  off = 1,
  default = 2
}

export const options = [
  { key: Options.default, text: "Default" },
  { key: Options.on, text: "On" },
  { key: Options.off, text: "Off" }
];

export const HINT = "Tenant default value:";
