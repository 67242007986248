import { convertToDateTime } from "../utils";

export const isValidNotificationDateAndTime = (options: {
  notificationStartDate: Date | undefined;
  notificationStartTime: string | undefined;
  notificationEndDate: Date | undefined;
  notificationEndTime: string | undefined;
}): string | undefined => {
  const {
    notificationStartDate,
    notificationStartTime,
    notificationEndDate,
    notificationEndTime
  } = options;
  if (
    !(
      notificationStartDate &&
      notificationStartTime &&
      notificationEndDate &&
      notificationEndTime
    )
  )
    return undefined;

  const notificationStartDateTime = convertToDateTime(
    notificationStartDate,
    notificationStartTime
  );

  const notificationEndDateTime = convertToDateTime(
    notificationEndDate,
    notificationEndTime
  );

  if (notificationStartDateTime >= notificationEndDateTime) {
    return "Notification start date and time must be before notification end date and time";
  }

  return undefined;
};

export const NotificationsValidator = {
  outageMessage: {
    maxLength: {
      value: 255,
      message: "Maximum length is 255 characters"
    }
  },
  dateRequired: {
    required: {
      message: "Date is required",
      value: true
    }
  },
  timeRequired: {
    required: {
      message: "Time is required",
      value: true
    }
  }
};
