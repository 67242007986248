import React from "react";

import { Stack, useTheme } from "@bps/fluent-ui";
import { VerticalTab, VerticalTabs } from "@components/VerticalTabs";

interface SoftwarePackageCommandTabsProps {
  commandTabs: VerticalTab[];
  selectedTab: string | undefined;
  onSelectedTabChanged: (id: string) => void;
}

export const SoftwarePackageCommandTabs: React.FC<SoftwarePackageCommandTabsProps> = ({
  commandTabs,
  selectedTab,
  onSelectedTabChanged
}) => {
  const theme = useTheme();

  return (
    <Stack
      styles={{
        root: {
          width: 300,
          borderColor: theme.semanticColors.variantBorder,
          borderWidth: 1,
          borderStyle: "solid",
          padding: theme.spacing.s1
        }
      }}
    >
      <VerticalTabs
        title="Commands"
        tabs={commandTabs}
        selectedTab={selectedTab}
        onSelectedTabChange={onSelectedTabChanged}
      />
    </Stack>
  );
};
