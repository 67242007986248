import { CopyableGuid } from "@components/CopyableText";
import { DataTable, DataTableColumn } from "@components/tables/DataTable";
import { DetailsRowExpand } from "@components/DetailsRowExpand";
import { Permissions } from "@libs/permissions/permissions.enum";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { PracticeLocationsExpand } from "modules/tenants/sections/best-health/locations/PracticeLocationsExpand";

import { FontIcon, Stack } from "@bps/fluent-ui";
import { PracticeLocation } from "@libs/api/gateways/best-health/practice/best-health-ops-gateway.dtos";

interface Props {
  practiceLocations: PracticeLocation[];
}

const getKey = (practiceLocation: PracticeLocation) => practiceLocation.id;

const StatusIconCell = ({ value }: { value: boolean }) => {
  const iconName = value ? "CheckMark" : "Cancel";

  return (
    <Stack grow horizontalAlign="center">
      <FontIcon iconName={iconName} />
    </Stack>
  );
};

export const PracticeLocationsTable = ({ practiceLocations }: Props) => {
  const { data: hasPreRelease } = usePermissionsCheckQuery(
    Permissions.OpsConsolePreRelease
  );

  const columns: DataTableColumn<PracticeLocation>[] = [
    {
      key: "id",
      name: "Id",
      width: 110,
      onRender: (practiceLocation: PracticeLocation) => (
        <CopyableGuid short value={practiceLocation.id} />
      )
    },
    {
      key: "locationName",
      name: "Name",
      minWidth: 200,
      sort: true
    },
    {
      key: "timeZoneId",
      name: "Time Zone",
      minWidth: 160,
      maxWidth: 240,
      sort: true,
      filterable: true
    },
    {
      key: "online-bookings",
      fieldName: "bookingSettings.enabled",
      name: "Online Bookings?",
      width: 120,
      onRender: (practiceLocation: PracticeLocation) => (
        <StatusIconCell value={practiceLocation.bookingSettings.enabled} />
      )
    }
  ];

  return (
    <DataTable
      items={practiceLocations}
      columns={columns}
      getKey={getKey}
      onShouldVirtualize={() => true}
      onRenderRow={
        hasPreRelease
          ? row => (
              <DetailsRowExpand
                row={row}
                expand={<PracticeLocationsExpand location={row?.item} />}
              />
            )
          : undefined
      }
    />
  );
};
