import { useFormContext } from "react-hook-form";

import {
  Stack,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  useTheme
} from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime } from "@bps/utils";
import { FormDatePicker } from "@components/form/fields/FormDatePicker";
import { FormTimePicker } from "@components/form/fields/FormTimePicker";

import { convertToDateTime } from "../utils";

interface RolloutTimePickerProps {
  rolloutOccurred: boolean;
  datePickerName: string;
  timePickerName: string;
  isStart: boolean;
}

export const RolloutTimePicker: React.FC<RolloutTimePickerProps> = ({
  rolloutOccurred,
  datePickerName,
  timePickerName,
  isStart
}) => {
  const theme = useTheme();
  const { control, watch } = useFormContext();

  const dateValue = watch(datePickerName);
  const timeValue = watch(timePickerName);

  return (
    <Stack>
      <Stack
        horizontal
        horizontalAlign="start"
        verticalAlign="center"
        tokens={{ childrenGap: theme.spacing.m }}
      >
        <TextBadge
          badgeColor={isStart ? TextBadgeColor.green : TextBadgeColor.red}
          badgeSize={TextBadgeSize.small}
        >
          {isStart ? "Start" : "End"}
        </TextBadge>

        <FormDatePicker
          disabled={rolloutOccurred}
          control={control}
          name={datePickerName}
          required
        />
        <FormTimePicker
          disabled={rolloutOccurred}
          control={control}
          name={timePickerName}
        />
      </Stack>
      <CurrentTimezoneInfo dateValue={dateValue} timeValue={timeValue} />
    </Stack>
  );
};

const CurrentTimezoneInfo: React.FC<CurrentTimezoneInfoProps> = ({
  dateValue,
  timeValue
}) => {
  if (!dateValue || !timeValue) {
    return <></>;
  }

  return (
    <Text variant="xSmall">
      {convertToDateTime(dateValue ?? new Date(0, 0, 0), timeValue ?? "00:00")
        .setZone(DateTime.now().timeZone)
        .toFormat(DATE_FORMATS.LOCALIZED_DATE_TIME)}
    </Text>
  );
};

interface CurrentTimezoneInfoProps {
  dateValue?: Date;
  timeValue?: string;
}
