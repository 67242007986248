import { FormProvider, useForm } from "react-hook-form";

import {
  PrimaryButton,
  Stack,
  StackItem,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { FormDropdown } from "@components/form/fields/FormDropdown";
import { FormTextField } from "@components/form/fields/FormTextField";
import { FieldDeviceAuthAction } from "@libs/api/gateways/field/field-ops-gateway.dtos";
import {
  useAuthActionsRefData,
  useFieldDeviceIdentifiers
} from "@libs/api/gateways/field/field-ops-gateway.hooks";
import { Validator } from "@components/form/validation/Validator";
import { validationResolver } from "@components/form/validation/validation-resolver";

export interface AuthActionFormValues {
  deviceAction: string;
  deviceIdentifierType: string;
  deviceIdentifierValue: string;
}

interface AuthActionFormProps {
  fieldDeviceId: string;
  authAction?: FieldDeviceAuthAction;
  onSubmit: (values: AuthActionFormValues) => void;
}

const sendNotificationValidator = new Validator<AuthActionFormValues>(
  validator => ({
    deviceIdentifierType: validator
      .string()
      .required({ message: "Identifier type is required" }),

    deviceIdentifierValue: validator.string().maxLength(255),
    deviceAction: validator
      .string()
      .required({ message: "Device action is required" })
  })
);

export const AuthActionForm: React.FC<AuthActionFormProps> = ({
  onSubmit,
  fieldDeviceId,
  authAction
}) => {
  const theme = useTheme();
  const { ...form } = useForm<AuthActionFormValues>({
    defaultValues: {
      deviceAction: authAction?.deviceAction,
      deviceIdentifierType: authAction?.deviceIdentifierType,
      deviceIdentifierValue: authAction?.deviceIdentifierValue
    },
    resolver: values =>
      validationResolver(sendNotificationValidator.validateWithParse, values)
  });

  const { data: authActionRefData } = useAuthActionsRefData();
  const { data: identifiers } = useFieldDeviceIdentifiers(fieldDeviceId);

  const idenitiferOptions =
    identifiers
      ?.map(identifier => identifier.identifierType)
      ?.filter((value, index, array) => array.indexOf(value) === index)
      .map(identifier => {
        return {
          key: identifier,
          text: identifier
        };
      }) ?? [];

  const actionOptions =
    authActionRefData?.map(refData => {
      return { key: refData.code, text: refData.code };
    }) ?? [];

  const currentAction = authActionRefData?.find(
    option => option.code === form.watch("deviceAction")
  );

  return (
    <FormProvider {...form}>
      <Stack
        as="form"
        tokens={{ childrenGap: theme.spacing.m }}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <Stack tokens={{ childrenGap: theme.spacing.s1 }}>
          <Text>On next authentication failure, if:</Text>
          <Stack
            horizontal
            styles={{ root: { justifyContent: "space-around" } }}
          >
            <Text>Identifer Type is</Text>
            <StackItem styles={{ root: { width: "70%" } }}>
              <FormDropdown
                required
                name="deviceIdentifierType"
                options={idenitiferOptions}
              />
            </StackItem>
          </Stack>

          <Stack
            horizontal
            styles={{ root: { justifyContent: "space-around" } }}
          >
            <Text>Identifer Value is</Text>
            <FormTextField
              required
              name="deviceIdentifierValue"
              styles={{ root: { width: "70%" } }}
            />
          </Stack>

          <FormDropdown
            styles={{ root: { width: "60%" } }}
            name="deviceAction"
            label="Action"
            options={actionOptions}
          />

          <Text>{currentAction?.text}</Text>
        </Stack>
        <Stack horizontal styles={{ root: { justifyContent: "right" } }}>
          <PrimaryButton type="submit">Submit</PrimaryButton>
        </Stack>
      </Stack>
    </FormProvider>
  );
};
