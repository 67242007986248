import { NotificationsTable } from "modules/system/sections/bp-mobile/components/NotificationsTable";
import { useState } from "react";

import {
  DefaultButton,
  Header,
  IIconProps,
  IStackTokens,
  Stack
} from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { withPermissions } from "@components/withPermissions";
import { BpMobileNotification } from "@libs/api/gateways/bp-mobile/bp-mobile.dtos";
import { useGetBpMobileAppNotifications } from "@libs/api/gateways/bp-mobile/bp-mobile.hooks";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { Permissions } from "@libs/permissions/permissions.enum";

import { NotificationDialog } from "./NotificationDialog";

const NotificationsBase = () => {
  const notificationsQuery = useGetBpMobileAppNotifications();

  const [showNotificationModal, setShowNotificationModal] = useState<boolean>();
  const [selectedNotification, setSelectedNotification] = useState<
    BpMobileNotification | undefined
  >();

  const addUserIcon: IIconProps = { iconName: "Add" };
  const stackTokens: IStackTokens = { childrenGap: 20 };

  const selectNotification = (
    notification: BpMobileNotification | undefined
  ) => {
    setSelectedNotification(notification);
    setShowNotificationModal(true);
  };

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.BhMobileNotificationsWrite
  );

  return (
    <Stack tokens={stackTokens} verticalFill>
      <Header>Bp Mobile Notifications</Header>
      <DefaultButton
        iconProps={addUserIcon}
        onClick={() => selectNotification(undefined)}
        disabled={!hasWritePermission}
        primary
        styles={{ root: { width: "fit-content" } }}
      >
        Add Notification
      </DefaultButton>
      <QueryStateIndicator<BpMobileNotification[]> {...notificationsQuery}>
        {notifications => (
          <NotificationsTable
            selectNotification={selectNotification}
            notifications={notifications}
          />
        )}
      </QueryStateIndicator>
      <NotificationDialog
        notification={selectedNotification}
        hidden={!showNotificationModal}
        onDismiss={() => setShowNotificationModal(false)}
      />
    </Stack>
  );
};

export const Notifications = withPermissions(
  NotificationsBase,
  [
    Permissions.BhMobileNotificationsRead,
    Permissions.BhMobileNotificationsWrite
  ],
  "or"
);
