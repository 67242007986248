import React, { FunctionComponent, useMemo } from "react";
import { Dialog, Option, Stack } from "@bps/fluent-ui";
import { Form } from "@components/form/Form";
import { FormApplicationsSelect } from "@components/form/fields/FormApplicationsSelect";
import { FormIconSelect } from "@components/form/fields/FormIconSelect";
import { FormSwitch } from "@components/form/fields/FormSwitch";
import { FormTextField } from "@components/form/fields/FormTextField";
import { FormComponentDefinitionSelect } from "@components/form/fields/FormComponentDefinitionSelect";
import { FormLicenceTypesSelect } from "modules/tenants/sections/plt/licencing/licence-form-dialog/FormLicenceTypesSelect";
import {
  defaultProductFormValues,
  ProductFormDialogBaseProps,
  ProductFormDialogProps,
  ProductFormValues
} from "./ProductFormDialog.types";
import { getProductFormValues } from "./utils";
import {
  useAddSalesProduct,
  useSalesProduct,
  useUpdateSalesProduct
} from "@libs/api/gateways/plt/plt-gateway.hooks";
import { Validator } from "@components/form/validation/Validator";
import { ValidationSchema } from "@bps/utils";

const NAME_VALIDATION_MESSAGE = "Maximum length is 40 characters.";
const DESCRIPTION_VALIDATION_MESSAGE = "Maximum length is 150 characters.";

const validator = new Validator<ProductFormValues>();
const schema: ValidationSchema<ProductFormValues> = {
  source: validator.string().required({ message: "Source is required" }),
  name: validator.string().required(),
  displayName: validator
    .string()
    .maxLength(40, { message: NAME_VALIDATION_MESSAGE }),
  description: validator
    .string()
    .maxLength(150, { message: DESCRIPTION_VALIDATION_MESSAGE })
};

const ProductFormDialogBase: FunctionComponent<Omit<
  ProductFormDialogBaseProps,
  "productId"
> & {
  productId: "new" | string;
}> = ({ closeDialog, productId, product }) => {
  const isNewProduct = productId === "new";

  const {
    mutateAsync: addProduct,
    error: addProductError
  } = useAddSalesProduct();

  const {
    mutateAsync: updateProduct,
    error: updateProductError
  } = useUpdateSalesProduct();

  const defaultValues = useMemo(() => {
    if (isNewProduct || !product) return defaultProductFormValues;
    return getProductFormValues(product);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  const onSubmit = async (values: ProductFormValues) => {
    const { isActive, source, ...rest } = values;
    const convertedValues = {
      isInactive: !isActive,
      source: !!source ? source : undefined
    };
    try {
      if (isNewProduct) {
        await addProduct({ ...rest, ...convertedValues });
      } else {
        product &&
          (await updateProduct({ ...product, ...rest, ...convertedValues }));
      }
      closeDialog();
    } catch (e) {
      return e;
    }
  };

  const title: string = `${isNewProduct ? "New" : "Edit"} product`;

  const productsIconsOptions: Option[] = [
    { key: "SchoolDataSyncLogo", text: "" },
    { key: "EaseOfAccess", text: "" },
    { key: "ProgressLoopOuter", text: "" }
  ];

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title,
        showCloseButton: true,
        onDismiss: closeDialog
      }}
      minWidth={700}
    >
      <Form<ProductFormValues>
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        error={addProductError ?? updateProductError}
        validate={values => validator.validateWithParse(values, schema)}
      >
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <FormTextField name="source" label="Source" required />
          <FormTextField name="sourceProductId" label="Source product id" />
          <FormSwitch label="Active" name="isActive" />
        </Stack>
        <FormTextField name="name" label="Name" required />
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <FormTextField name="displayName" label="Display name" />
          <FormIconSelect
            name="iconName"
            label="Icon"
            options={productsIconsOptions}
            styles={{ root: { width: 100 } }}
          />
        </Stack>
        <FormTextField
          name="description"
          multiline
          label="Description"
          description={DESCRIPTION_VALIDATION_MESSAGE}
        />
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <FormApplicationsSelect
            name="applicationCode"
            label="Application code"
            styles={{ root: { width: 325 } }}
          />
          <FormLicenceTypesSelect
            label="Licence type code"
            name="licenceType"
            styles={{ root: { width: 325 } }}
          />
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <FormTextField
            name="productFamily"
            label="Product family"
            styles={{ root: { width: 325 } }}
          />
          <FormTextField
            name="productCode"
            label="Code"
            styles={{ root: { width: 325 } }}
          />
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <FormTextField name="country" label="Country" disabled={true} />
          <FormTextField name="interval" label="Interval" disabled={true} />
        </Stack>

        <FormComponentDefinitionSelect
          name="components"
          label="Required components"
        />
      </Form>
    </Dialog>
  );
};

export const ProductFormDialog: FunctionComponent<ProductFormDialogProps> = props => {
  const isNewProduct = props.productId === "new";

  const { data } = useSalesProduct(props.productId!, {
    enabled: !isNewProduct && !!props.productId
  });
  if (!props.productId || (!isNewProduct && !data)) return null;

  return (
    <ProductFormDialogBase
      {...props}
      productId={props.productId}
      product={data}
    />
  );
};
