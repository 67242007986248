import React, { FunctionComponent } from "react";
import { FilterBar, useTheme } from "@bps/fluent-ui";
import {
  ApplicationType,
  TenantSearchArgs
} from "@libs/api/gateways/plt/plt-gateway.dtos";

interface TenantFilterValues
  extends Pick<
    TenantSearchArgs,
    "isInactive" | "name" | "applications" | "sortField" | "sortAscending"
  > {
  tenantId: string | undefined;
  crmId: string | undefined;
}

interface TenantsFilterProps {
  setArgs: (values: TenantSearchArgs) => void;
}
export const TenantsFilter: FunctionComponent<TenantsFilterProps> = ({
  setArgs
}) => {
  const theme = useTheme();
  return (
    <FilterBar<TenantFilterValues>
      maxItemWidth={200}
      initialValues={{ name: "", isInactive: false, tenantId: "", crmId: "" }}
      items={[
        {
          type: "searchBox",
          name: "name",
          props: {
            styles: { root: { minWidth: 220 } },
            placeholder: "Search tenants by Name",
            removeSpecialCharacters: false
          },
          stickItem: true
        },
        {
          type: "searchBox",
          name: "tenantId",
          props: {
            styles: { root: { minWidth: 360 } },
            placeholder: "Search tenants by Id",
            removeSpecialCharacters: false
          },
          stickItem: true
        },
        {
          type: "searchBox",
          name: "crmId",
          props: {
            styles: { root: { minWidth: 220 } },
            placeholder: "Search tenants by CrmId",
            removeSpecialCharacters: false
          },
          stickItem: true
        },
        {
          type: "optionsSelect",
          name: "applications",
          props: {
            placeholder: "Application",
            hideSearchOption: true,
            multiSelect: true,
            options: [
              { key: ApplicationType.OMNI, text: "Omni" },
              { key: ApplicationType.PROS, text: "Pros" },
              { key: ApplicationType.CAM, text: "Cam" }
            ]
          }
        }
      ]}
      onChange={({ values }) => {
        setArgs({ ...values, tenantIds: [values.tenantId ?? ""] });
      }}
      presets={[
        {
          name: "isInactive",
          iconName: "BlockContact",
          iconColor: theme.semanticColors.errorIcon,
          text: "Show inactive",
          textPressed: "Show active"
        }
      ]}
    />
  );
};
