import { DurationText } from "@components/DurationText";
import { SiteSyncState } from "@libs/api/gateways/sia/models/SiteSyncState";
import { useSequenceNumberSyncStates } from "./useSequenceNumberSyncStates";

import {
  DetailsList,
  IColumn,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  TooltipHost
} from "@bps/fluent-ui";

import { SequenceNumberSyncState } from "@libs/api/gateways/sia/sia-ops-gateway.dtos";
import { TimeText } from "@components/TimeText";

const columns: IColumn[] = [
  {
    key: "sequenceNumber",
    fieldName: "sequenceNumber",
    name: "Sequence #",
    minWidth: 80
  },
  {
    key: "status",
    fieldName: "status",
    name: "Status",
    minWidth: 80,
    onRender: (item: SequenceNumberSyncState) => {
      let badgeColor = TextBadgeColor.red;
      if (item.status === "Processed") {
        badgeColor = TextBadgeColor.green;
      }
      if (item.status === "Dequeued") {
        badgeColor = TextBadgeColor.yellow;
      }

      return (
        <TextBadge
          badgeColor={badgeColor}
          badgeSize={TextBadgeSize.small}
          styles={{ root: { width: "fit-content" } }}
        />
      );
    }
  },
  {
    key: "sentAt",
    fieldName: "sentAt",
    name: "Queued At",
    minWidth: 150,
    onRender: (item: SequenceNumberSyncState) => <TimeText time={item.sentAt} />
  },
  {
    key: "receiveDelayMillis",
    fieldName: "receiveDelayMillis",
    name: "Time enqueued",
    minWidth: 120,
    onRender: (item: SequenceNumberSyncState) => (
      <DurationText duration={item.receiveDelayMillis} />
    ),
    onRenderHeader: () => (
      <TooltipHost
        id="receive-delay-tooltip-id"
        content="How long the message spent waiting to be dequeued"
      >
        Time enqueued
      </TooltipHost>
    )
  },
  {
    key: "receivedAt",
    fieldName: "receivedAt",
    name: "Dequeued At",
    minWidth: 150,
    onRender: (item: SequenceNumberSyncState) => (
      <TimeText time={item.receivedAt} />
    )
  },
  {
    key: "processingDelayMillis",
    fieldName: "processingDelayMillis",
    name: "Time processing",
    minWidth: 120,
    onRender: (item: SequenceNumberSyncState) => (
      <DurationText duration={item.processingDelayMillis} />
    ),
    onRenderHeader: () => (
      <TooltipHost
        id="processing-delay-tooltip-id"
        content="The time spent processing the message after it had been dequeued"
      >
        Time processing
      </TooltipHost>
    )
  },
  {
    key: "processedAt",
    fieldName: "processedAt",
    name: "Processed At",
    minWidth: 150,
    onRender: (item: SequenceNumberSyncState) => (
      <TimeText time={item.processedAt} />
    )
  }
];

export const ReplicationQueueProcessingStateTable = ({
  state
}: {
  state: SiteSyncState;
}) => {
  const rows = useSequenceNumberSyncStates(state);

  return <DetailsList items={rows} columns={columns} />;
};
