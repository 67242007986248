import { FormDatePicker } from "@components/form/fields/FormDatePicker";
import { FormDropdown } from "@components/form/fields/FormDropdown";
import { FormTextField } from "@components/form/fields/FormTextField";
import { FunctionComponent } from "react";
import { useFormContext } from "react-hook-form";

import { IDropdownOption } from "@bps/fluent-ui";
import {
  ApplicationDto,
  CloudApplicationSecret
} from "@libs/api/gateways/plt-integrators/plt-integrators-gateway.dtos";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";

export interface CreateSecretFormProps {
  application: ApplicationDto;
  tenant: Tenant;
  onDismiss: () => void;
}

export const CreateSecretForm: FunctionComponent<CreateSecretFormProps> = () => {
  const expiryDateOptions: IDropdownOption[] = [
    { key: "3", text: "90 days (3 months)" },
    { key: "6", text: "180 days (6 months)" },
    { key: "12", text: "365 days (12 months)" },
    { key: "24", text: "730 days (24 months)" },
    { key: "Custom", text: "Custom" }
  ];

  const { watch, setValue } = useFormContext<CloudApplicationSecret>();
  const [startDateTime] = watch(["startDateTime"]);

  return (
    <>
      <FormTextField name="displayName" label="Description" required />
      <FormDropdown
        name="expires"
        placeholder="Expires"
        label="Expires"
        options={expiryDateOptions}
        onChange={(event, option) => {
          if (option!.key !== "Custom") {
            const endDateUpdated = new Date(startDateTime!);
            endDateUpdated.setMonth(
              startDateTime.getMonth() + parseInt(option!.key!.toString())
            );

            setValue("endDateTime", endDateUpdated, {
              shouldDirty: true,
              shouldValidate: true
            });
          }
        }}
      />
      <FormDatePicker
        required
        label="Start"
        name="startDateTime"
        calendarProps={{ minDate: new Date() }}
      />
      <FormDatePicker
        required
        label="End"
        name="endDateTime"
        calendarProps={{
          minDate: new Date(),
          maxDate: new Date(
            startDateTime!.getFullYear() + 2,
            startDateTime!.getMonth(),
            startDateTime!.getDay()
          )
        }}
      />
    </>
  );
};
