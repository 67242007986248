import { Dialog, IDialogProps, IDropdownOption } from "@bps/fluent-ui";
import { FormDropdown } from "@components/form/fields/FormDropdown";
import { Form } from "@components/form/Form";
import { TtmFormsRefreshOptions } from "@libs/api/gateways/ttm/forms/ttm-forms-ops-gateway.dtos";

interface FormsOptionsModalProps extends Pick<IDialogProps, "onDismiss"> {
  onSubmit: (options: TtmFormsRefreshOptions) => Promise<void>;
}

export const FormsOptionsModal = ({
  onDismiss,
  onSubmit
}: FormsOptionsModalProps) => {
  const options: IDropdownOption<string>[] = [
    { key: "ALL", text: "All" },
    { key: "Clinical", text: "Clinical" },
    { key: "PracticeManagement", text: "Practice Management" },
    { key: "ACC", text: "ACC" }
  ];

  return (
    <>
      <Dialog
        hidden={false}
        onDismiss={onDismiss}
        dialogContentProps={{ showCloseButton: true, title: "Refresh" }}
      >
        <Form<TtmFormsRefreshOptions>
          defaultValues={{ DomainCode: "ALL" }}
          submitButtonText="Send"
          onSubmit={onSubmit}
          cancelButtonText="Close"
          allowSubmitOnClean={true}
          showCancelButton
          onCancel={() => onDismiss?.()}
        >
          <FormDropdown name="DomainCode" label="Domains" options={options} />
        </Form>
      </Dialog>
    </>
  );
};
