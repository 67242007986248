import { FunctionComponent, useState } from "react";
import { useParams } from "react-router-dom";

import {
  DefaultButton,
  Dialog,
  FieldItemError,
  getInputFocusStyle,
  mergeStyles,
  useTheme
} from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { useTenantQuery } from "@libs/api/gateways/plt/plt-gateway.hooks";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";
import { AuBankAccountElement } from "@stripe/react-stripe-js";
import { StripeAuBankAccountElementOptions } from "@stripe/stripe-js";

import { AddNewDirectDebitSubmitButton } from "./AddNewDirectDebitSubmitButton";
import { StripeFormElements } from "./StripeFormElements";

export const AddNewDirectDebitDialog: FunctionComponent = () => {
  const [hidden, setHidden] = useState<boolean>(true);
  const [completed, setCompleted] = useState<boolean>(false);

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltSalesWrite
  );

  const [validationError, setValidationError] = useState<string | undefined>(
    undefined
  );

  const theme = useTheme();
  const base = mergeStyles({
    position: "relative",
    border: "1px solid",
    borderColor: theme.semanticColors.inputBorder,
    borderRadius: 5,
    padding: 8
  });

  const focus = mergeStyles(
    getInputFocusStyle(theme.semanticColors.inputFocusBorderAlt, 5)
  );

  const invalid = mergeStyles(
    getInputFocusStyle(theme.semanticColors.errorText, 5)
  );

  const options: StripeAuBankAccountElementOptions = {
    classes: { base, focus, invalid },
    style: {
      base: {
        color: theme.semanticColors.bodyText,
        fontFamily: '"Open Sans", sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "14px",
        padding: "8px",
        "::placeholder": {
          color: theme.semanticColors.inputPlaceholderText
        }
      },
      invalid: {
        color: theme.semanticColors.errorText,
        iconColor: theme.semanticColors.errorIcon
      }
    }
  };

  const onDismiss = () => {
    setHidden(true);
    setCompleted(false);
    setValidationError(undefined);
  };

  const params = useParams();

  const tenantQuery = useTenantQuery(params.tenantId!);

  return (
    <QueryStateIndicator {...tenantQuery}>
      {tenant => {
        return (
          <>
            {!hidden && (
              <Dialog
                minWidth={500}
                dialogContentProps={{
                  title: "Add a new direct debit",
                  showCloseButton: true,
                  styles: {
                    innerContent: { display: "flex", flexDirection: "column" }
                  }
                }}
                hidden={false}
                onDismiss={onDismiss}
                modalProps={{ className: "cam-AddNewCardDialog" }}
              >
                <StripeFormElements options={undefined} tenant={tenant}>
                  <AuBankAccountElement
                    options={options}
                    onChange={e => {
                      // Setting validation errors and complete state to disable Save button and show validation messages.
                      if (e.error) {
                        setValidationError(e.error.message);
                      } else {
                        if (e.complete) {
                          setCompleted(true);
                          setValidationError(undefined);
                        } else {
                          setCompleted(false);
                        }
                      }
                    }}
                  />
                  {validationError && (
                    <FieldItemError
                      name="directdebit"
                      errorMessage={validationError}
                    />
                  )}
                  <AddNewDirectDebitSubmitButton
                    onDismiss={onDismiss}
                    completed={completed}
                    validationError={validationError}
                  />
                </StripeFormElements>
              </Dialog>
            )}
            <DefaultButton
              disabled={!hasWritePermission}
              title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
              onClick={() => setHidden(false)}
            >
              Add a new direct debit
            </DefaultButton>
          </>
        );
      }}
    </QueryStateIndicator>
  );
};
