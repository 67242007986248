import { FunctionComponent } from "react";

import { DtoDetailsRow, IDetailsRowProps, Overlay } from "@bps/fluent-ui";
import { InvoiceDto } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { usePayInvoice } from "@libs/api/gateways/plt/plt-gateway.hooks";

export interface Helper {
  handlePayNow: (dto: {
    tenantId: string;
    invoiceNumber: string;
  }) => Promise<InvoiceDto>;
  isLoading: boolean;
  error: Error | undefined;
}

export const InvoiceRow: FunctionComponent<{
  props: IDetailsRowProps | undefined;
}> = ({ props }) => {
  const { isLoading, mutateAsync: payInvoice, error } = usePayInvoice();

  if (!props) return null;

  return (
    <div style={{ position: "relative" }}>
      <Overlay hidden={!isLoading} styles={{ root: { zIndex: 1 } }} />
      <DtoDetailsRow
        {...props}
        item={{
          ...props.item,
          helper: { handlePayNow: payInvoice, isLoading, error } as Helper
        }}
      />
    </div>
  );
};
