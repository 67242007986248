import React, { FunctionComponent } from "react";

import { Grid, LabeledText, LabeledTextGroup, Stack } from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { PltUser } from "@libs/api/gateways/plt/plt-gateway.dtos";
import {
  useTenantDefaultUserAuth,
  useUserDefaultAuth
} from "@libs/api/gateways/plt/plt-gateway.hooks";

interface UserAuthSettingsProps {
  user: PltUser;
}

export const UserAuthSettings: FunctionComponent<UserAuthSettingsProps> = ({
  user
}) => {
  const tenantInfoQuery = useTenantDefaultUserAuth(user.tenantId);
  const userInfoQuery = useUserDefaultAuth(user.tenantId, user.id);

  return (
    <QueryStateIndicator
      data={{ userAuth: userInfoQuery.data, tenantAuth: tenantInfoQuery.data }}
      isLoading={userInfoQuery?.isLoading || tenantInfoQuery?.isLoading}
      error={userInfoQuery?.error ?? tenantInfoQuery?.error}
      allowNullOrUndefined
    >
      {({ userAuth, tenantAuth }) => {
        return (
          <Stack horizontal tokens={{ childrenGap: 8 }}>
            <LabeledTextGroup styles={{ root: { marginBottom: 24 } }}>
              <Grid
                templateColumns="repeat(2, 320px)"
                childrenTokens={{ rowGap: 8, columnGap: 16 }}
              >
                <LabeledText wrap={false} label="User long password required">
                  {userAuth?.longPasswordRequired ||
                  tenantAuth?.longPasswordRequired
                    ? "Yes"
                    : "No"}
                </LabeledText>
                <Stack />
                <LabeledText wrap={false} label="User quick pin required">
                  {userAuth?.quickPinRequired || tenantAuth?.quickPinRequired
                    ? "Yes"
                    : "No"}
                </LabeledText>
                <LabeledText wrap={false} label="Pin lifetime">
                  {userAuth?.sessionMaxDuration
                    ? userAuth?.sessionMaxDuration
                    : tenantAuth?.sessionMaxDuration}
                </LabeledText>
                <LabeledText wrap={false} label="Setup Alternate Email MFA">
                  {userAuth?.requireAlternateEmailAuthenticationMethod ||
                  tenantAuth?.requireAlternateEmailAuthenticationMethod
                    ? "Yes"
                    : "No"}
                </LabeledText>
                <LabeledText
                  wrap={false}
                  label="Enable Alternate Email MFA during login"
                >
                  {userAuth?.requireAlternateEmailAuthenticationStrength ||
                  tenantAuth?.requireAlternateEmailAuthenticationStrength
                    ? "Yes"
                    : "No"}
                </LabeledText>
                <LabeledText wrap={false} label="Setup Mobile Phone MFA">
                  {userAuth?.requirePhoneAuthenticationMethod ||
                  tenantAuth?.requirePhoneAuthenticationMethod
                    ? "Yes"
                    : "No"}
                </LabeledText>
                <LabeledText
                  wrap={false}
                  label="Enable Mobile Phone MFA during login"
                >
                  {userAuth?.requirePhoneAuthenticationStrength ||
                  tenantAuth?.requirePhoneAuthenticationStrength
                    ? "Yes"
                    : "No"}
                </LabeledText>
                <LabeledText
                  wrap={false}
                  label="Setup Time based One Time Password (TOTP) MFA"
                >
                  {userAuth?.requireTotpAuthenticationMethod ||
                  tenantAuth?.requireTotpAuthenticationMethod
                    ? "Yes"
                    : "No"}
                </LabeledText>
                <LabeledText
                  wrap={false}
                  label="Enable Time based One Time Password MFA during login"
                >
                  {userAuth?.requireTotpAuthenticationStrength ||
                  tenantAuth?.requireTotpAuthenticationStrength
                    ? "Yes"
                    : "No"}
                </LabeledText>
              </Grid>
            </LabeledTextGroup>
          </Stack>
        );
      }}
    </QueryStateIndicator>
  );
};
