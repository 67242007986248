import { FunctionComponent, useMemo } from "react";
import { Dialog } from "@bps/fluent-ui";
import { isGUID } from "@bps/utils";
import { Form } from "@components/form/Form";
import { FormOptionsSelect } from "@components/form/fields/FormOptionsSelect";
import {
  useAddOrUpdateComponent,
  useComponentsDefs
} from "@libs/api/gateways/plt/plt-gateway.hooks";
import {
  CreateComponentDialogFormValues,
  CreateComponentDialogProps
} from "./CreateComponentDialog.types";
import { FormTextField } from "@components/form/fields/FormTextField";
import { ValidationMessages } from "@libs/validation/validation-messages.enum";
import { Validator } from "@components/form/validation/Validator";

const createCompValidator = new Validator<CreateComponentDialogFormValues>(
  validator => ({
    code: validator.string().required(),
    scopeId: [
      validator.string().required(),
      validator
        .custom()
        .func<"scopeId">(value =>
          isGUID(value ?? "") ? value : ValidationMessages.guid
        )
    ]
  })
);

export const CreateComponentDialog: FunctionComponent<CreateComponentDialogProps> = ({
  tenantId,
  onDismiss
}) => {
  const { data = [], isLoading, error } = useComponentsDefs();
  const {
    mutateAsync,
    error: addOrUpdateComponentError
  } = useAddOrUpdateComponent("A new component has nee added!");

  const options = useMemo(
    () => data.map(d => ({ key: d.code, text: d.text })),
    [data]
  );
  return (
    <Dialog
      hidden={false}
      onDismiss={onDismiss}
      dialogContentProps={{ title: "Create component", showCloseButton: true }}
    >
      <Form<CreateComponentDialogFormValues>
        defaultValues={{}}
        onSubmit={async values => {
          await mutateAsync({
            tenantId,
            code: values.code!,
            scopeId: values.scopeId
          });
          onDismiss();
        }}
        error={addOrUpdateComponentError}
        onCancel={onDismiss}
        showCancelButton
        validate={createCompValidator.validateWithParse}
      >
        <FormOptionsSelect
          name="code"
          loading={isLoading}
          errorMessage={error?.message}
          options={options}
          required
          label="Component code"
        />
        <FormTextField name="scopeId" label="Scope ID" required />
      </Form>
    </Dialog>
  );
};
