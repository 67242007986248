import { useRootStore } from "@stores/StoresProvider";
import { withPermissions } from "@components/withPermissions";
import { SectionTitle } from "@components/SectionTitle";
import { Permissions } from "@libs/permissions/permissions.enum";
import {
  ProsTenant,
  SiteComponentType
} from "@libs/api/gateways/sia/sia-ops-gateway.dtos";
import {
  ApplicationType,
  Tenant
} from "@libs/api/gateways/plt/plt-gateway.dtos";
import { useTenantsPltQuery } from "@libs/api/gateways/plt/plt-gateway.hooks";
import { useRef } from "react";
import { QueryClient, useQueryClient } from "react-query";

import {
  IObjectWithKey,
  Selection,
  SelectionMode,
  Stack,
  useTheme,
  FormState,
  ScrollablePane
} from "@bps/fluent-ui";

import { ProsDeployControls } from "./ProsDeployControls";
import { ProsDeployFilter, ProsDeployFilterValues } from "./ProsDeployFilter";
import { ProsDeployTable } from "./ProsDeployTable";
import { getPagesResult } from "@libs/paging/paging.utils";
import { SiaCacheKeys } from "@libs/api/gateways/sia/sia-ops-gateway.hooks";
import { CurrentSiteVersion } from "@libs/api/gateways/sia/models/CurrentSiteVersion";
import { ScrollListener } from "@components/tables/ScrollListener";

type ITenantWithKey = IObjectWithKey & Tenant;

const ProsDeployMgmtBase = () => {
  const theme = useTheme();
  const { data, isLoading, hasNextPage, fetchNextPage } = useTenantsPltQuery({
    isInactive: false,
    applications: [ApplicationType.PROS]
  });

  const queryClient = useQueryClient();
  const { deployment } = useRootStore();
  const selection = useRef<Selection<ITenantWithKey>>(
    new Selection<Tenant>({
      getKey: item => {
        return item.id;
      },
      onSelectionChanged: () => {
        deployment.setSelectedTenents(
          selection.current.getSelection() as Tenant[]
        );
      }
    })
  );

  const onClear = () => {
    selection.current.setAllSelected(false);
  };
  if (!data) {
    return null;
  }

  return (
    <>
      <SectionTitle>Deployment Management</SectionTitle>
      <Stack
        verticalFill
        styles={{
          root: {
            flex: 1,
            overflow: "hidden",
            position: "relative"
          }
        }}
      >
        <Stack
          tokens={{ childrenGap: theme.spacing.m }}
          styles={{
            root: {
              height: "100%",
              overflow: "hidden"
            }
          }}
        >
          <ProsDeployFilter>
            {(state: FormState<ProsDeployFilterValues>) => {
              const filteredItems = getFilteredItems(
                state.values,
                getPagesResult(data),
                queryClient
              );
              return (
                <Stack
                  styles={{ root: { position: "relative", height: "100%" } }}
                >
                  <ScrollablePane>
                    <ProsDeployTable
                      selectionPreservedOnEmptyClick
                      selectionMode={SelectionMode.multiple}
                      items={filteredItems || []}
                      enableShimmer={isLoading}
                      selection={selection.current}
                      setKey="deployment-table"
                      detailsListStyles={{
                        contentWrapper: {
                          ":hover": {
                            background: theme.palette.neutralQuaternaryAlt
                          }
                        }
                      }}
                    />
                    <ScrollListener
                      hasNextPage={hasNextPage ?? false}
                      onScrolledToBottom={async () => {
                        fetchNextPage();
                      }}
                    />
                  </ScrollablePane>
                </Stack>
              );
            }}
          </ProsDeployFilter>
          <ProsDeployControls onClear={onClear} />
        </Stack>
      </Stack>
    </>
  );
};

// Apply Filter Logic
const getFilteredItems = (
  filter: ProsDeployFilterValues,
  tenants: ProsTenant[],
  queryClient: QueryClient
) => {
  if (!tenants) {
    return [];
  }

  return tenants.filter(tenant => {
    const componentVersions = queryClient.getQueryData<CurrentSiteVersion[]>([
      SiaCacheKeys.SiteComponentVersions,
      tenant.id
    ]);

    const containsAgentVersion =
      filter.siteAgentVersion === undefined ||
      filter.siteAgentVersion.length === 0
        ? true
        : componentVersions?.find(
            x =>
              x.componentType === SiteComponentType.SiteAgent &&
              filter?.siteAgentVersion.includes(x.version!)
          ) !== undefined;

    const containsManagerVersion =
      filter.siteManagerVersion === undefined ||
      filter.siteManagerVersion.length === 0
        ? true
        : componentVersions?.find(
            x =>
              x.componentType === SiteComponentType.SiteManager &&
              filter?.siteManagerVersion.includes(x.version!)
          ) !== undefined;

    const noFiltersApplied =
      filter.siteAgentVersion.length === 0 &&
      filter.siteManagerVersion.length === 0;

    if ((containsAgentVersion && containsManagerVersion) || noFiltersApplied) {
      return (
        tenant.name
          ?.toLowerCase()
          .includes(filter.search.toLocaleLowerCase()) ||
        tenant.id.toLowerCase().includes(filter.search.toLocaleLowerCase())
      );
    }
    return false;
  });
};

export const ProsDeployMgmt = withPermissions(ProsDeployMgmtBase, [
  Permissions.OpsConsolePreRelease
]);
