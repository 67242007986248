import { FormProvider, useForm } from "react-hook-form";

import {
  DefaultButton,
  Dialog,
  DialogFooter,
  getTheme,
  IDialogProps,
  LabeledText,
  Stack
} from "@bps/fluent-ui";
import { CopyableGuid } from "@components/CopyableText";
import { FormButtonsGroupSingleChoice } from "@components/form/fields/FormButtonsGroupSingleChoice";
import { FormTextField } from "@components/form/fields/FormTextField";
import { CustomerAccountUser } from "@libs/api/gateways/cam/cam-gateway.dtos";

interface Props extends Pick<IDialogProps, "onDismiss"> {
  pltUser: CustomerAccountUser | undefined;
  onSave?: () => void;
}

export const UserModal = ({ pltUser: initialUser, onDismiss }: Props) => {
  const theme = getTheme();

  const form = useForm<CustomerAccountUser>({
    mode: "onChange",
    defaultValues: initialUser
  });

  const handleSubmit = async () => {
    onDismiss?.();
  };

  const handleCancel = () => {
    form.reset();
    onDismiss?.();
  };

  return (
    <FormProvider<CustomerAccountUser> {...form}>
      <Dialog
        dialogContentProps={{
          title: "Customer Account User",
          showCloseButton: true,
          onDismiss
        }}
        hidden={false}
      >
        <Stack
          as="form"
          onSubmit={form.handleSubmit(handleSubmit)}
          verticalFill
          tokens={{ childrenGap: theme.spacing.l2 }}
        >
          <Stack verticalFill tokens={{ childrenGap: theme.spacing.m }}>
            <Stack horizontal tokens={{ childrenGap: theme.spacing.s2 }}>
              <FormTextField name="firstName" label="First Name" disabled />
              <FormTextField name="lastName" label="Last Name" disabled />
            </Stack>
            <Stack horizontal tokens={{ childrenGap: theme.spacing.s2 }}>
              <FormTextField name="crmEmail" label="CRM Email" disabled />
            </Stack>
            <Stack horizontal tokens={{ childrenGap: theme.spacing.s2 }}>
              <FormTextField
                name="tenantUsername"
                label="Tenant Username"
                disabled
              />
            </Stack>
            <Stack>
              <FormButtonsGroupSingleChoice
                label="CRM Status"
                name="crmStatus"
                options={[
                  {
                    key: "ACTIVE",
                    text: "Active",
                    disabled: true
                  },
                  {
                    key: "INACTIVE",
                    text: "Inactive",
                    disabled: true
                  }
                ]}
              />
            </Stack>
            <Stack>
              <FormButtonsGroupSingleChoice
                label="BpCloud Status"
                name="status"
                options={[
                  {
                    key: "ACTIVE",
                    text: "Active",
                    disabled: true
                  },
                  {
                    key: "INACTIVE",
                    text: "Inactive",
                    disabled: true
                  }
                ]}
              />
            </Stack>

            <Stack>
              <FormTextField
                name="crmRoles"
                label="CRM Roles"
                multiline
                disabled
              />
            </Stack>
            <Stack horizontal tokens={{ childrenGap: theme.spacing.s1 }}>
              <LabeledText wrap={false} label="CRM Contact ID">
                <CopyableGuid value={initialUser?.crmContactId || ""} short />
              </LabeledText>
              <LabeledText wrap={false} label="Tenant ID">
                <CopyableGuid value={initialUser?.tenantId || ""} short />
              </LabeledText>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: theme.spacing.s1 }}>
              <LabeledText wrap={false} label="Tenant User ID">
                <CopyableGuid value={initialUser?.tenantUserId || ""} short />
              </LabeledText>
              <LabeledText wrap={false} label="BP ID User ID">
                <CopyableGuid value={initialUser?.bpIdUserId || ""} short />
              </LabeledText>
            </Stack>
          </Stack>

          <DialogFooter>
            <DefaultButton
              text="Close"
              onClick={handleCancel}
              data-test="cancel-button"
            />
          </DialogFooter>
        </Stack>
      </Dialog>
    </FormProvider>
  );
};
