import { DataTable, DataTableColumn } from "@components/tables/DataTable";
import { DateTimeWithDurationSinceTooltip } from "@components/DateTimeWithDurationSinceTooltip";
import { TableGuidDisplay } from "@components/TableGuidDisplay";

import {
  ShimmeredDetailsListProps,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize
} from "@bps/fluent-ui";
import { FieldDeviceIdentifier } from "@libs/api/gateways/field/field-ops-gateway.dtos";

interface IdentifierHistoryTableProps extends ShimmeredDetailsListProps {
  items: FieldDeviceIdentifier[];
}

export const IdentifierHistoryTable = ({
  items,
  ...props
}: IdentifierHistoryTableProps) => {
  const columns: DataTableColumn<FieldDeviceIdentifier>[] = [
    {
      key: "identifierType",
      name: "Name",
      minWidth: 150,
      maxWidth: 300,
      isResizable: true,
      onRender: (identifier: FieldDeviceIdentifier) => identifier.identifierType
    },
    {
      key: "identifierValue",
      name: "Value",
      minWidth: 150,
      isResizable: true,
      onRender: (identifier: FieldDeviceIdentifier) =>
        identifier.identifierValue
    },
    {
      key: "lastObservedUtc",
      name: "Last Observed",
      minWidth: 150,
      isSorted: true,
      sort: true,
      isSortedDescending: true,
      onRender: (identifier: FieldDeviceIdentifier) => (
        <DateTimeWithDurationSinceTooltip
          dateUtc={identifier.lastObservedUtc}
        />
      )
    },
    {
      key: "prevObservedUtc",
      name: "Previous Observed",
      minWidth: 150,
      onRender: (identifier: FieldDeviceIdentifier) => (
        <DateTimeWithDurationSinceTooltip
          dateUtc={identifier.prevObservedUtc}
        />
      )
    },
    {
      key: "lastSourceId",
      name: "Last Source ID",
      minWidth: 150,
      onRender: (identifier: FieldDeviceIdentifier) => (
        <TableGuidDisplay id={identifier.lastSourceId} />
      )
    },
    {
      key: "lastSourceType",
      name: "Last Source Type",
      onRender: (identifier: FieldDeviceIdentifier) => identifier.lastSourceType
    },
    {
      key: "observationCount",
      name: "Observation Count",
      onRender: (identifier: FieldDeviceIdentifier) =>
        identifier.observationCount
    },
    {
      key: "authenticated",
      name: "Authenticated",
      onRender: (identifier: FieldDeviceIdentifier) => {
        const authenticatedLabel = identifier.authenticated
          ? "Authenticated"
          : "Not Authenticated";

        return (
          <TextBadge
            hasBorder={true}
            badgeSize={TextBadgeSize.small}
            badgeColor={
              identifier.authenticated
                ? TextBadgeColor.green
                : TextBadgeColor.red
            }
          >
            {authenticatedLabel}
          </TextBadge>
        );
      }
    }
  ];

  return (
    <DataTable
      shimmerLines={10}
      items={items}
      columns={columns}
      onShouldVirtualize={() => true}
      {...props}
    />
  );
};
