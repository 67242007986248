import React, { FunctionComponent } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import { confirm, DefaultButton, Stack } from "@bps/fluent-ui";
import {
  InvoiceDto,
  InvoiceStatus,
  PaymentMethodDto,
  PaymentType,
  Tenant
} from "@libs/api/gateways/plt/plt-gateway.dtos";

import { TenantsTabs } from "../../../../tenants-tabs.constants";
import { Helper } from "./InvoiceRow";
import { InvoiceStatusBadge } from "./InvoiceStatusBadge";

interface InvoiceStatusColumnProps extends InvoiceDto {
  helper: Helper;
  paymentMethods: PaymentMethodDto[];
  tenant: Tenant;
}

const shouldPayInvoiceStatuses = [InvoiceStatus.Open, InvoiceStatus.Draft];
export const InvoiceStatusColumn: FunctionComponent<InvoiceStatusColumnProps> = props => {
  const navigate = useNavigate();

  const defaultPaymentMethod = props.paymentMethods.find(m => m.isDefault);

  return (
    <Stack horizontal tokens={{ childrenGap: 8 }}>
      <InvoiceStatusBadge
        status={props.status}
        failedMessage={props.errorMessage}
      />
      {shouldPayInvoiceStatuses.includes(props.status) &&
        defaultPaymentMethod !== undefined &&
        defaultPaymentMethod.type !== PaymentType.Becs && (
          <DefaultButton
            onClick={async () => {
              const hasDefaultMethods = props.paymentMethods?.some(
                m => m.isDefault
              );

              if (hasDefaultMethods) {
                try {
                  await props.helper.handlePayNow({
                    tenantId: props.tenant.id,
                    invoiceNumber: props.number
                  });
                } catch (e) {}
              } else {
                const isConfirmed = await confirm({
                  confirmButtonProps: {
                    text: "Go to payment methods page"
                  },
                  cancelButtonProps: {
                    text: "Cancel"
                  },
                  dialogContentProps: {
                    title: `${props.tenant.name} does not have a default payment method.`,
                    subText:
                      "Do you want to set a payment method as default one?"
                  }
                });

                if (isConfirmed) {
                  const to = generatePath("/tenants/:tenantId/:sectionId", {
                    tenantId: props.tenant.id,
                    sectionId: TenantsTabs.Sales.PaymentMethods.id
                  });
                  navigate(to);
                }
              }
            }}
            styles={{
              root: { width: "fit-content", height: 24, padding: 0 }
            }}
          >
            Pay now
          </DefaultButton>
        )}
    </Stack>
  );
};
