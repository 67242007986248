import React, { FunctionComponent, useRef } from "react";
import { useNavigate } from "react-router";

import { Form } from "@components/form/Form";
import { AddSubscriptionPayload } from "@libs/api/gateways/plt/plt-gateway.dtos";
import {
  useAddCustomerSubscription,
  useCustomerSubscriptionPreview
} from "@libs/api/gateways/plt/plt-gateway.hooks";

import { SubscriptionEditFormFields } from "./SubscriptionEditFormFields";
import { getSubscriptionsProducts } from "./subscription-form.utils";
import { PreviewSubscriptionDialog } from "../subscription-preview/PreviewSubscriptionDialog";
import { SUB_FORM_ID } from "./subscription-form.constants";
import { useParams } from "react-router-dom";
import { getPreviewSubscriptionPayload } from "../subscription-preview/preview-subscription.utils";
import {
  SubscriptionFormProps,
  SubscriptionFormValues
} from "./SubscriptionForm.types";
import { CenteredLargeSpinner, FontWeights } from "@bps/fluent-ui";

export const SubscriptionForm: FunctionComponent<SubscriptionFormProps> = ({
  customerProducts,
  tenant,
  childTenants
}) => {
  const { id } = useParams<{ id: string }>();

  const isPreviewed = useRef<boolean>(false);
  const {
    mutateAsync: submitSubscription,
    error: submitError
  } = useAddCustomerSubscription(tenant.id);

  const {
    mutateAsync: getSubscriptionPreview,
    error: previewError,
    data: previewData,
    isLoading: previewIsLoading,
    reset: resetPreviewData
  } = useCustomerSubscriptionPreview();

  const navigate = useNavigate();
  const subscription = customerProducts?.subscriptionList.find(
    s => s.subscriptionId === id
  );

  const subscriptionProducts = subscription
    ? getSubscriptionsProducts(subscription, childTenants).filter(
        s => !!s.quantity
      )
    : [];

  const handleSubmit = async (payload: AddSubscriptionPayload) => {
    resetPreviewData();
    await submitSubscription(payload);
    navigate(`../../../${tenant.id}/sales-subscriptions`);
  };

  const onTrySubmit = async (values: SubscriptionFormValues) => {
    const payload = getPreviewSubscriptionPayload(values, tenant.id);
    if (!isPreviewed.current) {
      isPreviewed.current = true;
      await getSubscriptionPreview(payload);
    } else {
      await handleSubmit(payload);
    }
  };

  return (
    <Form<SubscriptionFormValues>
      formId={SUB_FORM_ID}
      error={submitError ?? previewError}
      defaultValues={{
        subscriptionProducts,
        promotionCodeList: subscription?.promotionCodeList
      }}
      onSubmit={onTrySubmit}
      formStyles={{ root: { height: "100%", flexGrow: 1 } }}
      buttonsRootStyles={{ root: { marginTop: "auto" } }}
      hideButtons
      overlayProps={{
        children: (
          <CenteredLargeSpinner
            label={previewIsLoading ? "Loading preview ..." : "Submitting ..."}
            labelPosition="bottom"
            styles={{ label: { fontWeight: FontWeights.semibold } }}
          />
        )
      }}
    >
      <>
        <SubscriptionEditFormFields
          subscription={subscription}
          tenant={tenant}
        />
        {previewData && (
          <PreviewSubscriptionDialog
            previewData={previewData}
            onCancel={() => {
              isPreviewed.current = false;
              resetPreviewData();
            }}
          />
        )}
      </>
    </Form>
  );
};
