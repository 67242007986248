import { FunctionComponent } from "react";
import {
  Stack,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize
} from "@bps/fluent-ui";

interface PromotionCodesProps {
  promotionCodeList?: string[];
}
export const PromotionCodes: FunctionComponent<PromotionCodesProps> = ({
  promotionCodeList
}) => {
  if (!promotionCodeList || promotionCodeList.length === 0)
    return (
      <TextBadge
        badgeColor={TextBadgeColor.blue}
        hasBorder
        styles={{ root: { alignSelf: "start" } }}
        badgeSize={TextBadgeSize.small}
      >
        No active codes
      </TextBadge>
    );

  return (
    <Stack horizontal tokens={{ childrenGap: 6 }} wrap>
      {promotionCodeList.map(pc => (
        <TextBadge
          key={pc}
          badgeColor={TextBadgeColor.yellow}
          hasBorder
          badgeSize={TextBadgeSize.small}
        >
          {pc}
        </TextBadge>
      ))}
    </Stack>
  );
};
