import { Dialog, IDialogProps } from "@bps/fluent-ui";
import { Rollout } from "@libs/api/gateways/field/field-ops-gateway.dtos";
import { useCreateRollout } from "@libs/api/gateways/field/field-ops-gateway.hooks";

import { convertToDateTime } from "../utils";
import { confirmRolloutPast } from "./confirmRolloutPast";
import { RolloutForm, RolloutFormValues } from "./RolloutForm";

interface NewRolloutDialogProps extends IDialogProps {
  deploymentRingId: string;
  onDismiss: () => void;
}

export const NewRolloutDialog: React.FC<NewRolloutDialogProps> = ({
  deploymentRingId,
  onDismiss,
  ...props
}) => {
  const { mutateAsync: createRollout } = useCreateRollout();

  const onSubmit = async (formData: RolloutFormValues) => {
    const {
      displayName,
      isActive,
      initialDownloadTimeStartUtc,
      initialDownloadTimeEndUtc,
      initialInstallTimeStartUtc,
      initialInstallTimeEndUtc,
      initialDownloadDateStartUtc,
      initialDownloadDateEndUtc,
      initialInstallDateStartUtc,
      initialInstallDateEndUtc
    } = formData;

    const downloadStartUtc = convertToDateTime(
      initialDownloadDateStartUtc!,
      initialDownloadTimeStartUtc!
    );

    const downloadEndUtc = convertToDateTime(
      initialDownloadDateEndUtc!,
      initialDownloadTimeEndUtc!
    );

    const installStartUtc = convertToDateTime(
      initialInstallDateStartUtc!,
      initialInstallTimeStartUtc!
    );

    const installEndUtc = convertToDateTime(
      initialInstallDateEndUtc!,
      initialInstallTimeEndUtc!
    );

    confirmRolloutPast({
      downloadStartUtc,
      async changeState() {
        await createRollout({
          displayName,
          isActive: isActive!,
          deploymentRingId,
          initialDownloadStartUtc: downloadStartUtc.toISO(),
          initialDownloadEndUtc: downloadEndUtc.toISO(),
          initialInstallStartUtc: installStartUtc.toISO(),
          initialInstallEndUtc: installEndUtc.toISO()
        });
        onDismiss();
      }
    });
  };

  const newRollout: Rollout = {
    deploymentRingId,
    isActive: true
  };

  return (
    <Dialog
      dialogContentProps={{
        showCloseButton: true,
        title: "New Rollout"
      }}
      onDismiss={onDismiss}
      minWidth={410}
      {...props}
    >
      <RolloutForm rollout={newRollout} onSubmit={onSubmit} />
    </Dialog>
  );
};
