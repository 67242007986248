import { useMemo } from "react";
import { UseFormReturn } from "react-hook-form";

import { Dialog, IDialogProps } from "@bps/fluent-ui";
import { HttpError } from "@bps/http-client";
import { SelectorDto } from "@libs/api/gateways/field/field-ops-gateway.dtos";
import { useUpdateSelector } from "@libs/api/gateways/field/field-ops-gateway.hooks";

import { SelectorFormValues } from "./formUtils";
import { SelectorForm } from "./SelectorForm";

interface EditSelectorDialogProps extends IDialogProps {
  onDismiss: () => void;
  selector: SelectorDto;
  formError: HttpError | null;
  setFormError: React.Dispatch<React.SetStateAction<HttpError | null>>;
}

export const EditSelectorDialog: React.FC<EditSelectorDialogProps> = ({
  onDismiss,
  selector,
  formError,
  setFormError,
  ...props
}) => {
  const { mutateAsync: updateSelector, error } = useUpdateSelector();

  useMemo(() => {
    setFormError(error);
  }, [error, setFormError]);

  const onSubmit = async (
    values: SelectorFormValues,
    form: UseFormReturn<SelectorFormValues>
  ) => {
    const { rules, ...rest } = values;

    await updateSelector({
      ...selector,
      ...rest,
      rules: rules.map(rule => {
        const selectorRule = selector.rules.find(r => r.id === rule.id);
        return {
          ...selectorRule,
          attributeType: rule.attributeType,
          comparisonOperator: rule.comparisonOperator,
          value: rule.value,
          valueDataType: rule.valueDataType
        };
      })
    });

    // Reset form and dismiss model.
    onDismiss();
    setFormError(null); // reset form error message
    form.reset(values);
  };

  return (
    <Dialog
      dialogContentProps={{
        showCloseButton: true,
        title: "Edit Selector"
      }}
      minWidth="60%"
      onDismiss={onDismiss}
      {...props}
    >
      <SelectorForm
        onSubmit={onSubmit}
        onDismiss={onDismiss}
        selector={selector}
        error={formError}
      />
    </Dialog>
  );
};
