import { DetailsRowExpand } from "@components/DetailsRowExpand";
import { BhaProfileListExpand } from "modules/users/BhaProfile/BhaProfileListExpand";
import React, { useMemo } from "react";

import { DetailsList, IColumn, Stack, Text, useTheme } from "@bps/fluent-ui";
import { DATE_FORMATS } from "@bps/utils";
import { ConsumerUserProfile } from "@libs/api/gateways/best-health/account/best-health-account-ops-gateway.dtos";

interface BhaProfileListProps {
  profiles?: ConsumerUserProfile[];
}

export const BhaProfileList: React.FC<BhaProfileListProps> = ({
  profiles = []
}) => {
  const theme = useTheme();

  const columns = useMemo<IColumn[]>(
    () => [
      {
        isResizable: true,
        showSortIconWhenUnsorted: true,
        isSorted: true,
        isSortedDescending: false,
        onColumnClick: () => {},
        isRowHeader: true,
        name: "Name",
        key: "name",
        minWidth: 300,
        onRender: (profile: ConsumerUserProfile) => (
          <Stack horizontal tokens={{ childrenGap: theme.spacing.m }}>
            <Stack>
              <Text variant="large">
                {profile.lastName}, {profile.firstName}
              </Text>
              <Text variant="xSmall">{profile.id}</Text>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: theme.spacing.s1 }}>
              <Stack verticalFill verticalAlign="space-around">
                <Text variant="xSmall">
                  Family Members: {profile.familyMemberCount}
                </Text>
                <Text variant="xSmall">
                  Practice Count: {profile.practiceCount}
                </Text>
              </Stack>
              <Stack verticalFill verticalAlign="space-around">
                <Text variant="xSmall">
                  UserData Count: {profile.userDataCount}
                </Text>
                <Text variant="xSmall">
                  Device Count: {profile.deviceCount}
                </Text>
              </Stack>
            </Stack>
          </Stack>
        )
      },
      {
        isResizable: true,
        name: "Email",
        key: "email",
        minWidth: 250,
        fieldName: "email"
      },
      {
        isResizable: true,
        name: "Mobile",
        key: "mobile",
        minWidth: 100,
        fieldName: "mobile"
      },
      {
        isResizable: true,
        name: "DOB",
        key: "dob",
        minWidth: 100,
        onRender: (profile: ConsumerUserProfile) =>
          profile.dateOfBirth?.toFormat(DATE_FORMATS.DAY_DEFAULT_FORMAT)
      },
      {
        isResizable: true,
        name: "Updated",
        key: "updatedOn",
        minWidth: 100,
        onRender: (profile: ConsumerUserProfile) =>
          profile.updatedOn?.toFormat(DATE_FORMATS.DAY_DEFAULT_FORMAT)
      }
    ],
    [theme]
  );

  return (
    <DetailsList
      items={profiles || []}
      columns={columns}
      onRenderRow={row => (
        <DetailsRowExpand
          row={row}
          expand={<BhaProfileListExpand profile={row?.item} />}
        />
      )}
      onShouldVirtualize={() => true}
    />
  );
};
