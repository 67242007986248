import { useCallback, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import {
  Heading,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { FormDatePicker } from "@components/form/fields/FormDatePicker";
import { FormTextField } from "@components/form/fields/FormTextField";
import { FormTimePicker } from "@components/form/fields/FormTimePicker";
import { BpMobileNotification } from "@libs/api/gateways/bp-mobile/bp-mobile.dtos";

import {
  isValidNotificationDateAndTime,
  NotificationsValidator
} from "./NotificationsValidator";

export interface NotificationFormValues {
  outageMessage?: string;
  notificationTimeStartUtc?: string;
  notificationTimeEndUtc?: string;
  notificationDateStartUtc?: Date;
  notificationDateEndUtc?: Date;
}

interface NotificationFormProps {
  notification: BpMobileNotification;
  onSubmit: (values: NotificationFormValues) => void;
}

const parseNotification = (
  notification?: BpMobileNotification
): NotificationFormValues => {
  return {
    ...notification,
    notificationTimeStartUtc: notification?.startTime?.toTimeInputFormat(),
    notificationTimeEndUtc: notification?.endTime?.toTimeInputFormat(),
    notificationDateStartUtc: notification?.startTime?.toJSDate(),
    notificationDateEndUtc: notification?.endTime?.toJSDate(),
    outageMessage: notification?.outageMessage
  };
};

export const NotificationForm: React.FC<NotificationFormProps> = ({
  notification,
  onSubmit
}) => {
  const [validationError, setValidationError] = useState<string | undefined>();

  const theme = useTheme();
  const { control, ...form } = useForm<NotificationFormValues>({
    defaultValues: parseNotification(notification)
  });

  const _onSubmit = useCallback<SubmitHandler<NotificationFormValues>>(
    values => {
      const {
        notificationDateStartUtc,
        notificationDateEndUtc,
        notificationTimeStartUtc,
        notificationTimeEndUtc
      } = values;

      const error = isValidNotificationDateAndTime({
        notificationStartDate: notificationDateStartUtc,
        notificationStartTime: notificationTimeStartUtc,
        notificationEndDate: notificationDateEndUtc,
        notificationEndTime: notificationTimeEndUtc
      });

      setValidationError(error);

      if (error) return;

      onSubmit({
        ...values
      });
    },
    [onSubmit]
  );

  return (
    <FormProvider {...{ control, ...form }}>
      <Stack
        as="form"
        tokens={{ childrenGap: theme.spacing.m }}
        onSubmit={form.handleSubmit(_onSubmit)}
      >
        {validationError && (
          <MessageBar messageBarType={MessageBarType.error}>
            {validationError}
          </MessageBar>
        )}

        <Stack tokens={{ childrenGap: theme.spacing.s1 }}>
          <FormTextField
            control={control}
            name="outageMessage"
            label="Message"
            multiline
            rules={NotificationsValidator.outageMessage}
            styles={{ field: { height: "150px" } }}
          />

          <Heading labelPaddings>Notification window (UTC Time)</Heading>
          <Stack
            horizontal
            horizontalAlign="space-between"
            verticalAlign="center"
          >
            <FormDatePicker
              control={control}
              name="notificationDateStartUtc"
              rules={NotificationsValidator.dateRequired}
            />
            <Text>to</Text>
            <FormDatePicker
              control={control}
              name="notificationDateEndUtc"
              rules={NotificationsValidator.dateRequired}
            />
          </Stack>
          <Stack
            horizontal
            horizontalAlign="space-between"
            verticalAlign="center"
          >
            <FormTimePicker
              control={control}
              name="notificationTimeStartUtc"
              rules={NotificationsValidator.timeRequired}
            />
            <Text>to</Text>
            <FormTimePicker
              control={control}
              name="notificationTimeEndUtc"
              rules={NotificationsValidator.timeRequired}
            />
          </Stack>
        </Stack>

        <Stack horizontal>
          <PrimaryButton type="submit">Submit</PrimaryButton>
        </Stack>
      </Stack>
    </FormProvider>
  );
};
