import { withPermissions } from "@components/withPermissions";
import { SideNavLayout } from "@layouts/SideNavLayout";
import { Permissions } from "@libs/permissions/permissions.enum";

import { SystemBpMobileRoutes } from "./SystemBpMobileRoutes";
import { SystemBpMobileSections } from "./SystemBpMobileSections";

const SystemBpMobileBase = () => (
  <SideNavLayout nav={<SystemBpMobileSections />}>
    <SystemBpMobileRoutes />
  </SideNavLayout>
);

export const SystemBpMobile = withPermissions(
  SystemBpMobileBase,
  [
    Permissions.BhMobileNotificationsRead,
    Permissions.BhMobileNotificationsWrite,
    Permissions.BhMobileSettingsRead,
    Permissions.BhMobileSettingsWrite
  ],
  "or"
);
