import { Stack, Text, useTheme } from "@bps/fluent-ui";
import { FunctionComponent } from "react";
import {
  Country,
  SubscriptionPreviewDto
} from "@libs/api/gateways/plt/plt-gateway.dtos";
import { currencyFormat } from "../../../invoices/utils";

interface ProRataCostsProps {
  previewData: SubscriptionPreviewDto;
  country: Country;
}

export const ProRataCosts: FunctionComponent<ProRataCostsProps> = ({
  previewData,
  country
}) => {
  const theme = useTheme();
  const thenTotal = previewData.items.reduce((acc, item) => {
    const price = item.salesProductPrice / 100;
    const qty = item.childTenants.reduce((acc, item) => acc + item.quantity, 0);
    return acc + price * qty;
  }, 0);

  return (
    <Stack>
      <Stack horizontal tokens={{ childrenGap: 16 }}>
        <Text variant="xxLarge">Total due today:</Text>
        <Text variant="xxLarge">
          {currencyFormat((previewData?.amount ?? 0) / 100, country)}
        </Text>
      </Stack>

      <Text styles={{ root: { color: theme.palette.neutralSecondary } }}>
        Then {currencyFormat(thenTotal, country)} per month
      </Text>
    </Stack>
  );
};
