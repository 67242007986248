import { FunctionComponent } from "react";

import {
  IColumn,
  Link,
  ScrollablePane,
  ScrollbarVisibility,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime } from "@bps/utils";
import { SectionTitle } from "@components/SectionTitle";
import { ShimmeredDetailsList } from "@components/tables/ShimmeredDetailsList";
import { withPermissions } from "@components/withPermissions";
import { InvoiceDto, Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";
import {
  useBillingHistory,
  useTenantPaymentMethods
} from "@libs/api/gateways/plt/plt-gateway.hooks";
import { Permissions } from "@libs/permissions/permissions.enum";
import { stripeAccountBaseUrl } from "@libs/stripe/stripe.utils";

import { Helper, InvoiceRow } from "./components/InvoiceRow";
import { InvoiceStatusColumn } from "./components/InvoiceStatusColumn";
import { currencyFormat } from "./utils";

interface InvoicesScreenProps {
  tenant: Tenant;
}

const InvoicesScreenBase: FunctionComponent<InvoicesScreenProps> = ({
  tenant
}) => {
  const theme = useTheme();
  const { data, isLoading, error } = useBillingHistory(tenant.id);

  const {
    data: paymentMethods,
    isLoading: isLoadingPaymentMethods,
    error: errorPaymentMethods
  } = useTenantPaymentMethods(tenant.id);

  const columns: IColumn[] = [
    {
      name: "Date",
      key: "date",
      minWidth: 50,
      maxWidth: 75,
      onRender: (item: InvoiceDto) =>
        item.created
          ? DateTime.fromISO(item.created).toFormat(
              DATE_FORMATS.DAY_DEFAULT_FORMAT
            )
          : undefined
    },
    {
      name: "Invoice",
      key: "invoice",
      minWidth: 50,
      maxWidth: 75,
      onRender: (item: InvoiceDto) => (
        <Link
          href={`${stripeAccountBaseUrl()}invoices/${item.id}`}
          target="_blank"
        >
          {item.number}
        </Link>
      )
    },
    {
      name: "Status",
      key: "status",
      minWidth: 75,
      maxWidth: 100,
      onRender: (item: InvoiceDto & { helper: Helper }) => (
        <InvoiceStatusColumn
          {...item}
          tenant={tenant}
          paymentMethods={paymentMethods ?? []}
        />
      )
    },
    {
      name: "Description",
      key: "description",
      minWidth: 100,
      maxWidth: 250,
      onRender: (item: InvoiceDto) => item.description
    },
    {
      name: "Total (ex GST)",
      key: "total_ex",
      minWidth: 100,
      maxWidth: 125,
      onRender: (item: InvoiceDto) =>
        item.total !== undefined && item.tax !== undefined
          ? currencyFormat((item.total - item.tax) / 100, tenant.country)
          : undefined
    },
    {
      name: "GST",
      key: "gst",
      minWidth: 75,
      maxWidth: 100,
      onRender: (item: InvoiceDto) =>
        item.tax !== undefined
          ? currencyFormat(item.tax / 100, tenant.country)
          : undefined
    },
    {
      name: "Total (inc GST)",
      key: "total_inc",
      minWidth: 100,
      maxWidth: 125,
      onRender: (item: InvoiceDto) =>
        item.total !== undefined
          ? currencyFormat(item.total / 100, tenant.country)
          : undefined
    }
  ];

  return (
    <Stack
      verticalFill
      styles={{ root: { padding: theme.spacing.s1 } }}
      tokens={{ childrenGap: theme.spacing.l2 }}
    >
      <Stack>
        <SectionTitle>Billing History</SectionTitle>
        <Link
          href={`${stripeAccountBaseUrl()}invoices?customer=${
            tenant.stripeCustomerId
          }`}
          target="_blank"
        >
          Manage customer invoices
        </Link>
      </Stack>
      <Stack
        tokens={{ childrenGap: 8 }}
        styles={{ root: { position: "relative" } }}
        grow
      >
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <ShimmeredDetailsList
            columns={columns}
            stickyHeader
            items={data ?? []}
            enableShimmer={isLoading ?? isLoadingPaymentMethods}
            errorMessage={error?.message ?? errorPaymentMethods?.message}
            onRenderRow={props => {
              if (!props) return null;
              return <InvoiceRow props={props} />;
            }}
          />
        </ScrollablePane>
      </Stack>
    </Stack>
  );
};

export const InvoicesScreen = withPermissions(
  InvoicesScreenBase,
  [Permissions.PltSalesRead, Permissions.PltSalesWrite],
  "or"
);
