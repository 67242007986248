import { CommandRunnerProvider } from "../../../_shared-models/CommandRunner";
import { CopyableGuid } from "@components/CopyableText";
import { config } from "@libs/config/config";
import { TenantSectionProps } from "modules/tenants/sections/plt/types";

import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  IconButton
} from "@bps/fluent-ui";

import { TenantResource } from "@libs/api/gateways/plt/plt-gateway.dtos";

interface IResourceProps {
  isActiveSqlResource: boolean;
  appType?: string;
  server?: string;
  database?: string;
}

const getResourceProps = (resource: TenantResource): IResourceProps => {
  const isActiveSqlResource =
    resource?.azResourceType === "MSSQLDB" &&
    resource?.bpResourceName !== "" &&
    !resource?.bpResourceName.toLowerCase().startsWith("deprovisioned");

  let appType: "ttm" | "pros" | undefined;
  if (resource?.bpResourceName?.toLowerCase().startsWith("titanium")) {
    appType = "ttm";
  }

  if (resource?.bpResourceName?.toLowerCase().startsWith("pros")) {
    appType = "pros";
  }

  const server = isActiveSqlResource
    ? resource.connectionString?.split(".")[0]
    : undefined;

  const database =
    isActiveSqlResource && resource.connectionString.indexOf(";")
      ? resource.connectionString?.split(";")[1]
      : undefined;

  return { isActiveSqlResource, appType, server, database };
};

const columns: IColumn[] = [
  {
    key: "id",
    fieldName: "id",
    name: "Id",
    minWidth: 300,
    maxWidth: 300,
    onRender: (resource: TenantResource) => (
      <CopyableGuid value={resource.id} />
    ),
    flexGrow: 0
  },
  {
    key: "azResourceType",
    fieldName: "azResourceType",
    name: "Type",
    minWidth: 100,
    maxWidth: 140,
    flexGrow: 0
  },
  {
    key: "bpResourceName",
    fieldName: "bpResourceName",
    name: "Name",
    minWidth: 150,
    maxWidth: 250,
    flexGrow: 0
  },
  {
    key: "connectionString",
    fieldName: "connectionString",
    name: "Description",
    minWidth: 250,
    maxWidth: 800,
    flexGrow: 1
  },
  {
    key: "actions",
    name: "Actions",
    minWidth: 50,
    maxWidth: 50,
    flexGrow: 0,
    onRender: (resource: TenantResource) => {
      const resourceProps = getResourceProps(resource);
      if (!resourceProps.isActiveSqlResource) return;
      return (
        <IconButton
          styles={{ root: { justifySelf: "end" } }}
          menuIconProps={{ iconName: "more" }}
          menuProps={{
            items: [
              {
                key: "resourceOverviewLink",
                text: "Open in Azure portal",
                iconProps: { iconName: "OpenInNewTab" },
                onClick: () => {
                  const url = config.azureResourcesSqlUrl
                    .replace("[APPTYPE]", resourceProps.appType ?? "")
                    .replace("[SERVER]", resourceProps.server ?? "")
                    .replace("[DATABASE]", resourceProps.database ?? "");
                  window.open(url, "_blank", "noreferrer");
                }
              }
            ]
          }}
        />
      );
    }
  }
];

export const TenantResourcesTable = ({ tenant }: TenantSectionProps) => {
  return (
    <CommandRunnerProvider>
      <DetailsList
        items={tenant.resources ?? []}
        columns={columns}
        layoutMode={DetailsListLayoutMode.justified}
      />
    </CommandRunnerProvider>
  );
};
