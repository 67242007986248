import { Dialog, IDialogProps } from "@bps/fluent-ui";
import { Rollout } from "@libs/api/gateways/field/field-ops-gateway.dtos";
import { useUpdateRollout } from "@libs/api/gateways/field/field-ops-gateway.hooks";

import { convertToDateTime } from "../utils";
import { confirmRolloutPast } from "./confirmRolloutPast";
import { RolloutForm, RolloutFormValues } from "./RolloutForm";

interface EditRolloutDialogProps extends IDialogProps {
  rollout: Rollout;
  onDismiss: () => void;
}

export const EditRolloutDialog: React.FC<EditRolloutDialogProps> = ({
  rollout,
  onDismiss,
  ...props
}) => {
  const { mutateAsync: updateRollout } = useUpdateRollout();

  const onSubmit = async (formData: RolloutFormValues) => {
    const {
      displayName,
      isActive,
      initialDownloadTimeStartUtc,
      initialDownloadTimeEndUtc,
      initialInstallTimeStartUtc,
      initialInstallTimeEndUtc,
      initialDownloadDateStartUtc,
      initialDownloadDateEndUtc,
      initialInstallDateStartUtc,
      initialInstallDateEndUtc
    } = formData;

    const downloadStartUtc = convertToDateTime(
      initialDownloadDateStartUtc!,
      initialDownloadTimeStartUtc!
    );

    const downloadEndUtc = convertToDateTime(
      initialDownloadDateEndUtc!,
      initialDownloadTimeEndUtc!
    );

    const installStartUtc = convertToDateTime(
      initialInstallDateStartUtc!,
      initialInstallTimeStartUtc!
    );

    const installEndUtc = convertToDateTime(
      initialInstallDateEndUtc!,
      initialInstallTimeEndUtc!
    );

    confirmRolloutPast({
      downloadStartUtc,
      async changeState() {
        await updateRollout({
          id: rollout.id!,
          displayName,
          isActive: isActive!,
          deploymentRingId: rollout.deploymentRingId,
          initialDownloadStartUtc: downloadStartUtc.toISO(),
          initialDownloadEndUtc: downloadEndUtc.toISO(),
          initialInstallStartUtc: installStartUtc.toISO(),
          initialInstallEndUtc: installEndUtc.toISO(),
          eTag: rollout.eTag!
        });
        onDismiss();
      }
    });
  };

  return (
    <Dialog
      dialogContentProps={{
        showCloseButton: true,
        title: "Edit Rollout"
      }}
      onDismiss={onDismiss}
      minWidth={410}
      {...props}
    >
      <RolloutForm rollout={rollout} onSubmit={onSubmit} />
    </Dialog>
  );
};
