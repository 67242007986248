import { CopyableGuid } from "@components/CopyableText";
import { YesOrNoIcon } from "@components/YesOrNoIcon";
import React from "react";

import { DetailsList, IColumn, FontIcon } from "@bps/fluent-ui";
import { DATE_FORMATS } from "@bps/utils";
import { PracticeLocationProvider } from "@libs/api/gateways/best-health/practice/best-health-ops-gateway.dtos";

interface ProvidersTableProps {
  providers: PracticeLocationProvider[];
}

enum ProviderOnlineStatus {
  Yes = "YES",
  No = "NO",
  Call = "CALL"
}

export const PracticeLocationProvidersTable: React.FC<ProvidersTableProps> = ({
  providers
}) => {
  const columns: IColumn[] = [
    {
      key: "providerId",
      name: "Provider Id",
      fieldName: "providerId",
      minWidth: 310,
      maxWidth: 310,
      onRender: (item: PracticeLocationProvider) => (
        <CopyableGuid value={item.providerId} />
      )
    },
    {
      key: "displayName",
      name: "Display Name",
      fieldName: "displayName",
      minWidth: 150,
      maxWidth: 250
    },
    {
      key: "onlineBookingsEnabled",
      name: "Bookings Enabled",
      minWidth: 150,
      maxWidth: 150,
      onRender: (item: PracticeLocationProvider) => {
        if (item.providerOnlineStatus === ProviderOnlineStatus.Call) {
          return (
            <>
              <FontIcon
                iconName="Phone"
                styles={{ root: { fontSize: 16, marginRight: 4 } }}
              />
              Call clinic
            </>
          );
        }

        return (
          <YesOrNoIcon
            when={item.providerOnlineStatus === ProviderOnlineStatus.Yes}
          />
        );
      }
    },
    {
      key: "firstAvailable",
      name: "First Available",
      minWidth: 100,
      maxWidth: 150,
      onRender: (item: PracticeLocationProvider) =>
        item.firstAvailable?.toFormat(DATE_FORMATS.DAY_DEFAULT_FORMAT)
    },
    {
      key: "lastAvailable",
      name: "Last Available",
      minWidth: 100,
      maxWidth: 150,
      onRender: (item: PracticeLocationProvider) =>
        item.lastAvailable?.toFormat(DATE_FORMATS.DAY_DEFAULT_FORMAT)
    },
    {
      key: "availabilityCount",
      name: "Availability Count",
      fieldName: "availabilityCount",
      minWidth: 100,
      maxWidth: 200
    }
  ];
  return <DetailsList columns={columns} items={providers} />;
};
